<template>
    <v-form ref="form">
        <div class="resumo">
            <div class="resumo-title">Resumo do acordo</div>
            <div class="resumo-conteudo">
                <div class="resumo-item">
                    <span class="titulo">Cadastro do Acordo:</span>
                    <p>
                        Periodo de
                        <strong>{{ formatarMes(value.mesInicial) }}</strong> até
                        <strong>{{ formatarMes(value.mesFinal) }}</strong>
                    </p>
                    <p>
                        Espaço: <strong>Exclusivo Negociação comercial</strong>
                    </p>

                    <p>
                        Bandeira:
                        <strong>{{ bandeira }}</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <span class="titulo">Informações adicionais:</span>
                    <p>
                        forma de pagamento:
                        <strong>{{ value.forma_pagamento }}</strong>
                    </p>
                    <p>
                        Número de parcelas:
                        <strong>{{ value.parcelas_pagamento }}</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <span class="titulo">Seleção de Produtos:</span>
                    <p>
                        Fornecedor: <strong>{{ value.fornecedor }}</strong>
                    </p>
                    <p>
                        Produtos:
                        <strong>{{ produtosQuantidade}} selecionados</strong>
                    </p>
                </div>
                <div class="resumo-item resumo-total">
                    <p>
                        Total de investimento do acordo:
                        <span style="color: var(--v-primary-base)">
                            <formataReal :valor="value.investimento_total" />
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
export default {
    props: ['value'],
    inject: ['listaCampos'],
    components: {
    },
    async created() {
        var lista = await this.listaCampos
        // this.lista.espaco = lista.espaco
        // this.lista.categorias = lista.categorias
        // this.lista.device = lista.device
    },

    data() {
        return {
            resumo: {},
 
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
 
            semAcesso: false,
        }
    },
    mounted() {
    },
    computed: {
        produtosQuantidade() {
            if (this.value.produtos){

                return this.value.produtos.length
            }
            return 0
        },
        espaco() {
            if (this.value.id_espaco && this.lista.espaco) {
                const espaco = this.lista.espaco.find(
                    (e) => e.value === this.value.id_espaco
                )
                if (espaco && !this.value.ponta_tematica) {
                    return espaco.text
                } else {
                    console.log('aqui->', this.value)
                    return this.value.prateleira_nome
                }
            }
            return ''
        },
        bandeira() {
            if (this.value.bandeira && this.value.bandeira?.length > 0) {
                return this.value.bandeira.join(', ')
            }
            return ''
        },
    },
    methods: {
        // setAcordo(acordo) {
        //     this.acordo = { ...acordo }
        // },
        // setResumo(resumo) {
        //     console.log(resumo)
        //     this.resumo = resumo
        // },

        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso
        },
        validate() {
            return this.$refs.form.validate()
        },
        resetValidation() {
            this.resumo = {}
            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }
        },
        formatarMes(mes) {
            return this.$moment(mes, 'YYYY-MM')
                .format('MMMM [de] YYYY')
                .capitalize()
        },

        formataMoeda(valor = 0) {
            return valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
        },
    
    },
}
</script>
<style lang="scss">
.resumo {
    .resumo-total {
        grid-column: span 6;
        text-align: center;
        font-size: 1rem;
        margin-top: 1rem;
    }
    .resumo-title {
        font-size: 1.1rem;
        background: var(--v-primary-base);
        text-align: center;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px 8px 0 0;
    }
    .resumo-conteudo {
        display: grid;
        grid-template-columns: 1fr 20px 1fr 20px 1fr;
        grid-gap: 15px;
        align-items: center;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        font-weight: 500;
        font-size: 0.8rem !important;
        color: #808080;
        border: 1px solid #ddd;
        p {
            margin: 0;
        }
        .resumo-item {
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            border-radius: 8px;
            background: #eaeaea;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .titulo {
                font-weight: bold;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>