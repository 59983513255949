<template>
    <div class="my-3">
        <!-- <v-row no-gutters>
            <v-col cols="12" align="end">
                <v-icon
                    class="mb-2 mr-2"
                    color="primary"
                    @click="expandIndicadores()"
                >
                    
                </v-icon>
            </v-col>
        </v-row> -->
        <!-- <pre>{{ filtro.filtro }}</pre> -->
        <v-expand-transition>
            <v-row>
                <!-- <v-col md="4">
                    <div @click="alterarPlanograma('is not null')">
                        <Indicador
                            titulo=" Acordos com Planograma"
                            :valor="indicadores.comPlanograma"
                            icone="$alertCircleCheckOutline"
                            :class="{
                                active: planogramaAtivo('is not null'),
                            }"
                        />
                    </div>
                </v-col> -->
                <!-- <v-col md="4">
                    <div @click="alterarPlanograma('is null')">
                        <Indicador
                            titulo=" Acordos sem Planograma"
                            :valor="indicadores.semPlanograma"
                            icone="$alertCircleCheckOutline"
                            :class="{
                                active: planogramaAtivo('is null'),
                            }"
                        />
                    </div>
                </v-col> -->
                <v-col md="4">
                    <div @click="alterarStatus(1)">
                        <Indicador
                            titulo="Aguardando Aprovação Trade"
                            :valor="indicadores[1]"
                            icone="$alertCircleCheckOutline"
                            :class="{
                                active: status.includes(1),
                            }"
                        />
                    </div>
                </v-col>

                <!-- <v-col md="4">
                    <div @click="alterarStatus(30)">
                        <Indicador
                            titulo="Pré acordos"
                            :valor="indicadores[30]"
                            icone="$alertCircleCheckOutline"
                            :class="{
                                active: status.includes(30),
                            }"
                        />
                    </div>
                </v-col> -->

                <v-col md="4">
                    <div @click="alterarStatus(29)">
                        <Indicador
                            titulo="Espaço Reservado"
                            :valor="indicadores[29]"
                            icone="$circleSlice"
                            :class="{
                                active: status.includes(29),
                            }"
                        />
                    </div>
                </v-col>

                <v-col md="4">
                    <div @click="alterarStatus(15)">
                        <Indicador
                            titulo="Aguardando Gestão"
                            :valor="indicadores[15]"
                            icone="$documentAlertOutline"
                            :class="{
                                active: status.includes(15),
                            }"
                        />
                    </div>
                </v-col>

                <v-col md="4">
                    <div @click="alterarStatus(16)">
                        <Indicador
                            titulo="Contratos Emitidos"
                            :valor="indicadores[16]"
                            icone="$documentCheck"
                            :class="{
                                active: status.includes(16),
                            }"
                        />
                    </div>
                </v-col>

                <v-col md="4">
                    <div @click="alterarStatus(28)">
                        <Indicador
                            titulo="Acordos Cancelados"
                            :valor="indicadores[28]"
                            icone="$documentRemove"
                            :class="{
                                active: status.includes(28),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alterarStatus(2)">
                        <Indicador
                            titulo="Acordos Reprovados"
                            :valor="indicadores[2]"
                            icone="$documentRemove"
                            :class="{
                                active: status.includes(2),
                            }"
                        />
                    </div>
                </v-col>
            </v-row>
            <!-- {{ filtro.filtro.statusIndicadores  }} -->
        </v-expand-transition>
    </div>
</template>

<script>
import Indicador from "./Indicador.vue";

export default {
    components: {
        Indicador,
    },
    props: ["indicadores", "filtro"],
    computed: {
        status: function () {
            return this.filtro?.filtro?.id_status || [];
        },
        planograma: function () {
            return this.filtro?.filtro?.planograma || [];
        },
    },
    data() {
        return {
            expand: true,
            expandIcon: "mdi-chevron-up",
            //indicadores: {},
        };
    },
    methods: {
        alterarStatus: function (status) {
            this.filtro.filtro.planograma = [];

            if (this.status.includes(status)) {
                this.filtro.filtro.id_status = this.status.filter(
                    (s) => s != status
                );
            } else {
                this.filtro.filtro.id_status = [status];
            }
            this.filtro.filtrar();
        },
        alterarPlanograma(value) {
            this.filtro.filtro.id_status = [];
            console.log(value);
            if (this.filtro.filtro.planograma.includes(value)) {
                this.filtro.filtro.planograma = [];
                console.log("igual");
            } else {
                this.filtro.filtro.planograma = [value];
                console.log("diferente");
            }
            this.filtro.filtrar();
        },
        planogramaAtivo: function (value) {
            return this.filtro?.filtro?.planograma?.includes(value) || false;
        },
    },
};
</script>