var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-2"},[_c('v-expand-transition',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(2)}}},[_c('Indicador',{class:{
                            active: _vm.id_status_planejamento && _vm.id_status_planejamento.includes(2),
                        },attrs:{"titulo":"Ativações Ok","valor":_vm.indicadores.status_ok,"icone":"mdi-check-circle","color":"success"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(1)}}},[_c('Indicador',{class:{
                            active: _vm.id_status_planejamento && _vm.id_status_planejamento.includes(1),
                        },attrs:{"titulo":"Ativações Pendentes","valor":_vm.indicadores.status_pendente,"icone":"mdi-alert-circle","color":"warning"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(3)}}},[_c('Indicador',{class:{
                            active: _vm.id_status_planejamento && _vm.id_status_planejamento.includes(3),
                        },attrs:{"titulo":"Ativações Canceladas","valor":_vm.indicadores.status_cancelado,"icone":"mdi-text-box-remove-outline","color":"error"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(7)}}},[_c('Indicador',{class:{
                            active: _vm.id_status_planejamento && _vm.id_status_planejamento.includes(7),
                        },attrs:{"titulo":"Ativações Realocadas","valor":_vm.indicadores.status_realocado,"icone":"mdi-text-box-remove-outline","color":"error"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(6)}}},[_c('Indicador',{class:{
                            active: _vm.id_status_planejamento && _vm.id_status_planejamento.includes(6),
                        },attrs:{"titulo":"Ativações Enviadas","valor":_vm.indicadores.status_enviado,"icone":"mdi-send-check","color":"error"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao('oferta-do-dia')}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('oferta-do-dia'),
                        },attrs:{"titulo":"Ofertas do dia","valor":_vm.indicadores['oferta-do-dia'],"icone":"mdi-tag-multiple-outline","color":"warning"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao('produto-patrocinado-busca')}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('produto-patrocinado-busca'),
                        },attrs:{"titulo":"Patrocinado e Autocomplete","valor":_vm.indicadores['produto-patrocinado-busca'],"icone":"mdi-cash-multiple","color":"success"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao('vitrine-patrocinada')}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('vitrine-patrocinada'),
                        },attrs:{"titulo":"Vitrine patrocinada","valor":_vm.indicadores['vitrine-patrocinada'],"icone":"mdi-storefront-outline","color":"warning"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao('oferta-relampago')}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('oferta-relampago'),
                        },attrs:{"titulo":"Ofertas Relâmpago","valor":_vm.indicadores['oferta-relampago'],"icone":"mdi-flash","color":"error"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao('compre-junto')}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('compre-junto'),
                        },attrs:{"titulo":"Compre junto","valor":_vm.indicadores['compre-junto'],"icone":"mdi-cart-plus","color":"warning"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao("vitrine-patrocinada-pdp")}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('vitrine-patrocinada-pdp'),
                        },attrs:{"titulo":"Vitrine patrocinada PDP","valor":_vm.indicadores['vitrine-patrocinada-pdp'],"icone":"mdi-storefront","color":"success"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao("com-arte")}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('com-arte'),
                        },attrs:{"titulo":"Espacos com arte","valor":_vm.indicadores['com-arte'],"icone":"mdi-contain","color":"success"}})],1)]),_c('v-col',{attrs:{"md":"4"}}),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao("hotpage")}}},[_c('Indicador',{class:{
                            active: _vm.ativacao && _vm.ativacao.includes('hotpage'),
                        },attrs:{"titulo":"Hotpage","valor":_vm.indicadores['hotpage'],"icone":"mdi-fire","color":"success"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }