<template>
    <v-app :class="{ expanded: mini }">
        <v-app-bar
            height="80px"
            clipped-left
            app
            elevation="0"
            color="white"
            class="w-100"
            style="z-index: 100"
        >
            <div class="d-flex justify-space-between align-center w-100">
                <v-img
                    src="@/assets/images/login/logo_sem_nome.png"
                    height="100%"
                    max-width="100"
                    contain
                    @click="rotaHome"
                ></v-img>

                <div class="homologacao" v-if="$store.getters.getSistemaExtra">
                    <v-toolbar-title @click="abrirDialogo" class="page-title"
                        >Ambiente de Homologação</v-toolbar-title
                    >
                </div>

                <div @click.stop="drawerUsuario = !drawerUsuario">
                    <div class="d-flex">
                        <v-avatar color="primary" size="52">
                            <span
                                class="white--text headline"
                                style="font-size: 1.5rem !important"
                                >{{ nome.substr(0, 2).toUpperCase() }}</span
                            >
                        </v-avatar>
                        <div class="mx-4 my-auto">
                            <p
                                class="my-0"
                                style="font-weight: normal; line-height: 1rem"
                            >
                                {{ nome }}
                            </p>

                            <span class="my-0 meu-perfil"
                                >Meu perfil
                                <v-icon>mdi-chevron-down</v-icon></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </v-app-bar>

        <v-navigation-drawer
            class="menu-principal"
            clipped
            permanent
            app
            width="300"
            v-model="drawer"
            :mini-variant.sync="mini"
        >
            <v-list dense nav>
                <v-list-item :link="mini">
                    <v-list-item-icon @click.stop="miniMenu" v-if="mini">
                        <v-icon>mdi-menu</v-icon>
                    </v-list-item-icon>

                    <div
                        class="primary--text d-flex"
                        style="font-weight: 500; flex-grow: 1"
                    >
                        Olá, seja bem-vindo!
                    </div>

                    <v-btn
                        style="background: #e6e6e6; width: 20px; height: 20px"
                        fab
                        icon
                        @click.stop="mini = !mini"
                        x-small
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item>

                <v-divider></v-divider>
                <template v-for="(item, index) in itens">
                    <v-list-item
                        v-if="item.rota"
                        :key="index"
                        @click="rotaMenu(item)"
                        link
                        :class="item.rota === menuClique ? 'menu-active' : ''"
                    >
                        <v-list-item-icon>
                            <v-icon color="grey lighten-1">{{
                                item.icon
                            }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group
                        v-if="item.itens"
                        :key="index"
                        v-model="item.active"
                        :prepend-icon="item.icon"
                        prepend-icon-color="red"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.title
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-slot:prependIcon>
                            <v-icon
                                class="Andy"
                                :color="
                                    item.active ? 'primary' : 'grey lighten-1'
                                "
                                >{{ item.icon }}</v-icon
                            >
                        </template>

                        <v-list-item
                            v-for="subItem in item.itens"
                            :key="subItem.title"
                            @click="rotaMenu(subItem)"
                            :class="
                                subItem.rota === menuClique ? 'menu-active' : ''
                            "
                        >
                            <v-list-item-icon>
                                <v-icon
                                    :color="
                                        subItem.rota === menuClique
                                            ? 'primary'
                                            : 'grey lighten-1'
                                    "
                                    >{{ subItem.icon }}</v-icon
                                >
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    subItem.title
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list>

            <template v-slot:append content-class="logo-trade-mais">
                <div class="mx-4 my-auto">
                    <v-img
                        src="@/assets/images/login/logo_trade.png"
                        width="100%"
                        max-width="250px"
                        contain
                    ></v-img>
                    <p
                        class="my-0"
                        style="
                            font-weight: normal;
                            line-height: 1rem;
                            text-align: center;
                        "
                    >
                        <b>Versão: {{ versaoSistema }}</b>
                    </p>
                </div>
            </template>
        </v-navigation-drawer>

        <v-main class="fundo">
            <div
                no-gutters
                v-if="this.$route.path.split('/')[1] == 'simulacao'"
            ></div>

            <v-dialog persistent v-model="senhaPadrao" width="600">
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Alterar Senha
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-form ref="form">
                            <v-row no-gutters>
                                <v-col cols="12" class="px-2">
                                    <v-text-field
                                        label="Senha Antiga"
                                        v-model="senha.antiga"
                                        outlined
                                        dense
                                        :rules="regra.antiga"
                                        required
                                        type="password"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="px-2">
                                    <v-text-field
                                        label="Nova Senha"
                                        v-model="senha.nova"
                                        outlined
                                        dense
                                        :rules="regra.nova"
                                        required
                                        type="password"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="px-2">
                                    <v-text-field
                                        label="Confirmar Senha"
                                        v-model="senha.confirmar"
                                        outlined
                                        dense
                                        :rules="regra.confirmar"
                                        required
                                        type="password"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" @click="salvarSenha()">
                            Salvar Senha
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <div
                :class="{
                    'body-main': true,
                    expanded: mini,
                }"
            >
                <router-view />
            </div>

            <v-navigation-drawer
                width="300px"
                class="menu-principal"
                v-model="drawerUsuario"
                absolute
                temporary
                right
            >
                <v-list-item>
                    <v-list-item-icon class="pl-2 mr-0">
                        <v-icon color="grey lighten-1"
                            >mdi-account-settings</v-icon
                        >
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ login }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense nav class="pb-0">
                    <v-list-item link @click="rotaPerfil">
                        <v-list-item-icon class="pl-2 mr-0">
                            <v-icon color="grey lighten-1"
                                >mdi-account-cog</v-icon
                            >
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                >Perfil do Usuário</v-list-item-title
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list dense nav class="pt-0">
                    <v-list-item link @click="logout()">
                        <v-list-item-icon class="pl-2 mr-0">
                            <v-icon color="grey lighten-1">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                >Sair da sessão</v-list-item-title
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <DownloadStatus
                    titulo="vb-001-4515454555s4d545554.csv"
                    :valor="45"
                    icone="mdi-download-circle"
                    color="success"
                />
            </v-navigation-drawer>

            <v-footer class="justify-center" color="transparent">
                IPDV © {{ new Date().getFullYear() }} | Todos os direitos
                reservados | Política de Privacidade
            </v-footer>
        </v-main>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
        <v-dialog
            content-class="br-15"
            scrollable
            v-model="dialogoDealhes"
            width="600"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Detalhes técnicos </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoDealhes = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <div class="w-100">
                        <pre style="white-space: pre-wrap; font-size: 0.8rem"
                            >{{ $store.getters.getSistemaExtra }} </pre
                        >
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import DownloadStatus from './_components/DownloadStatus.vue'
export default {
    data() {
        return {
            drawerUsuario: false,
            dialogoDealhes: false,
            items: [
                {
                    title: 'Perfil do Usuário',
                    icon: 'mdi-account-settings',
                    rota: 'rotaPerfil',
                },
                {
                    title: 'Sair da Sessão',
                    icon: 'mdi-account-cog',
                    rota: 'logout',
                },
            ],
            itens: [],
            drawer: true,
            menuClique: '',
            mini: null,
            senha: {
                antiga: null,
                nova: null,
                confirmar: null,
            },
            regra: {
                antiga: [(v) => !!v || 'O campo senha antiga é obrigatório'],
                nova: [
                    (v) => !!v || 'O campo nova senha é obrigatório',
                    (v) =>
                        (v && v.length >= 8) ||
                        'A senha deve ter no minimo 8 caracteres.',
                ],
                confirmar: [
                    (v) => !!v || 'O campo confirmar senha é obrigatório',
                    (v) =>
                        (v && v == this.senha.nova) ||
                        'As senhas não conferem.',
                ],
            },
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            versaoSistema: this.$store.getters.appVersion.substring(2),
            // sistemaExtra: this.$
        }
    },
    components: {
        DownloadStatus,
    },
    async created() {
        this.$gtag.set({ user_id: this.$store.getters.getUsuario.id_usuario })
        this.$gtag.set({
            cargo_id: this.$store.getters.getUsuario.id_cargo || 0,
        })
        this.$gtag.config({
            user_id: this.$store.getters.getUsuario.id_usuario,
        })

        this.$gtag.event('cargo', {
            id_cargo: this.$store.getters.getUsuario.id_cargo || 0,
            value: 'id_' + this.$store.getters.getUsuario.id_cargo || 0,
        })

        if (this.$store.getters.getUsuario.id_fornecedor) {
            this.$gtag.set({
                id_fornecedor: this.$store.getters.getUsuario.id_fornecedor,
            })
        }

        var response = await this.$http().post('/usuario/usuarioMenu', {})

        var menu = response.data.length ? response.data : []

        var itens = []
        for (const pai of menu.filter((p) => p.pai == 0)) {
            let itemPai = {
                title: pai.nome,
                icon: pai.icone,
            }

            if (pai.caminho) {
                itemPai.rota = pai.caminho
            } else {
                itemPai.itens = []
            }

            for (const filho of menu.filter(
                (f) => f.pai == pai.id_menu && f.visivel
            )) {
                let itemFilho = {
                    title: filho.nome,
                    icon: filho.icone,
                    rota: filho.caminho,
                }

                itemPai.itens.push(itemFilho)
            }

            itens.push(itemPai)
        }

        this.itens = itens
    },
    mounted() {
        if (this.$route.name == 'main') {
            this.$router.push({ name: 'home' })
        }
        this.mini = localStorage.getItem('miniMenu') == 'true' ? true : false

        this.verificaVersao()
    },

    computed: {
        login() {
            return this.$store.getters.getUsuario.login
        },
        nome() {
            return this.$store.getters.getUsuario.nome
        },
        senhaPadrao() {
            return this.$store.getters.getSenhaPadrao
        },
        cargo() {
            return this.$store.state.auth.cargo
        },
    },

    methods: {
        abrirDialogo() {
            this.dialogoDealhes = true
        },
        async salvarSenha() {
            if (this.$refs.form.validate()) {
                this.carregando = true

                var response = await this.$http().post(
                    '/usuario/alterarSenha',
                    {
                        data: this.senha,
                    }
                )

                if (response.data.sucesso) {
                    this.alerta = true
                    this.alertaColor = 'success'
                    this.alertaTimeout = -1
                    this.alertaTexto = response.data.msg

                    setTimeout(() => {
                        this.alerta = false
                        this.carregando = false
                        this.$router.push('/login')
                    }, 1000)
                } else {
                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = response.data.msg

                    this.carregando = false
                }
            }
        },
        miniMenu() {
            localStorage.setItem('miniMenu', !this.mini)
            this.mini = !this.mini
        },
        rotaMenu(item) {
            if (this.$route.name != item.rota) {
                this.menuClique = item.rota
                this.$router.push({ name: item.rota })
            }
        },
        rotaMenuSimulacao(rota) {
            if (this.$route.name != rota) {
                this.$router.push({ name: rota })
            }
        },
        rotaHome() {
            if (this.$route.name != 'home') {
                this.$router.push({ name: 'home' })
            }
        },
        rotaPerfil() {
            if (this.$route.name != 'perfil') {
                this.$router.push({ name: 'perfil' })
            }
        },
        logout() {
            this.$store.dispatch('logout')
        },
        async verificaVersao() {
            var response = await this.$http().post('/login/verificaVersao', {
                versao: this.$store.getters.appVersion,
            })
            console.log(response.data)
            if (response.data.extras) {
                this.$store.dispatch('setExtra', response.data.extras)
                console.log(response.data.extras)
            }

            if (!response.data.sucesso) {
                //window.location.reload(true);
                this.alerta = true
                this.alertaColor = '#FFA500'
                this.alertaTimeout = -1
                this.alertaTexto =
                    'O sistema foi atualizado para a versão ' +
                    response.data.versao.substring(2) +
                    '. Por favor, atualize seu browser usando Ctrl + F5.'
            }
        },
    },
}
</script>

<style>
/* 
   estilos
    │ 
    └─────> data table  
    │ 
    └─────> botoes  
    │ 
    └─────> highcharts    
*/

/* borda menu */

.v-navigation-drawer__border {
    width: 0px !important;
}

/* fundo do main */

.fundo {
    background: #f2f2f2;
    padding: 10px;
}

.v-main__wrap {
    padding: 10px;
}
/* *********************** data table inicio *********************** */

.v-data-table-header {
    background-color: var(--v-primary-base);
}
/* .data-table-2 .v-data-table-header {
  background-color: #fff !important;
} */

.v-data-table-header__icon {
    color: #fff !important;
}

/* .v-data-table-header .v-icon {
  color: #fff !important;
} */

.data-table-2 .v-data-table-header__icon {
    color: var(--v-primary-base) !important;
}

.data-table-2 .v-data-table-header .v-icon {
    color: var(--v-primary-base) !important;
}

th {
    color: #fff !important;

    white-space: nowrap;
    padding: 6px !important;
    font-weight: 500;
}

.data-table-2 th {
    color: var(--v-primary-base) !important;
}

td {
    white-space: nowrap;
    padding: 6px !important;
}

.nowrap-no td {
    white-space: normal !important;
}

.sortable span {
    padding-left: 18px;
}

.v-data-table-header__sort-badge {
    color: #fff !important;
    padding-left: 0 !important;
}

/* *********************** data table fim *********************** */

/* *********************** botoes inicio *********************** */

/* .v-btn.v-size--default {
  font-size: 0.75rem !important;
} */

.v-btn {
    letter-spacing: normal;
    font-weight: 400;
}

/* *********************** botoes fim *********************** */

/* *********************** highcharts inicio *********************** */

.highcharts-root {
    font-family: 'Roboto', sans-serif !important;
}
.v-application {
    font-family: 'TT Norms', 'Roboto', 'Work Sans', sans-serif;
}
.highcharts-button-symbol {
    color: #cccccc !important;
    stroke: #cccccc !important;
    fill: #cccccc !important;
    background: red;
}

.highcharts-menu-item {
    font-size: 0.8125rem !important;
    line-height: 1rem !important;
}

.highcharts-menu-item:hover {
    background: var(--v-primary-base) !important;
}

.highcharts-title {
    fill: #000 !important;
    font-weight: normal;
    font-size: 1.3rem !important;
    font-family: 'TT Norms Pro' !important;
}

.grafico-cor-primary {
    fill: var(--v-primary-base);
    stroke: var(--v-primary-base);
}

.simulator-alert {
    color: #707070;
    font-size: 0.7rem;
    background: #fff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: #eee 0px 0px 8px;
    display: inline-block;
    line-height: 0.8rem;
    margin: 10px 20px;
}

.menu-active .v-list-item__title {
    /* background: #ccc; */
    color: var(--v-primary-base) !important;
}
.menu-active .v-icon {
    color: var(--v-primary-base) !important;
}

/* *********************** highcharts fim *********************** */

.input-cinza fieldset {
    background-color: #f2f2f2;
    border-width: 2px;
    border-color: transparent;
    transition-property: color, background, border-color, border-radius;
}

.input-cinza.v-input--is-dirty fieldset,
.input-cinza .v-input--is-dirty fieldset {
    background-color: #f2f2f2;
    color: #808080;
}

.input-cinza.v-input--is-focused fieldset,
.input-cinza.active fieldset,
.input-cinza .v-input--is-focused fieldset,
.input-cinza .active fieldset {
    background-color: #fff;
    border-color: var(--v-primary-base);
}

.input-cinza.v-select--is-menu-active fieldset,
.input-cinza.active fieldset,
.input-cinza .v-select--is-menu-active fieldset,
.input-cinza .active fieldset {
    border-radius: 6px 5px 0 0;
    border-bottom: 1px solid #eaeaea;
}

.input-cinza fieldset legend,
.input-cinza.active fieldset legend {
    width: 0 !important;
}

.input-cinza .v-label--active {
    transform: none !important;
}

.input-cinza .v-label--active {
    color: #808080 !important;
}

.input-cinza.v-input--is-dirty .v-label--active,
.input-cinza .v-input--is-dirty .v-label--active {
    top: 0px !important;
    left: -10px !important;
    color: #808080 !important;
    transform: translateY(-16px) scale(0.75) !important;
}

.input-cinza {
    transition: margin-bottom 0.3s ease-in-out;
}
.input-cinza.error--text {
    margin-bottom: 20px;
}
.input-cinza.error--text fieldset,
.input-cinza .error--text fieldset {
    border: 2px solid red;
}
.input-cinza .v-text-field__details {
    padding-left: 0 !important;
}
.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
}
.autocomplete-selecao {
    max-width: 80%;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
}

/* Fim input cinza */
.menu-principal .v-list-item__icon {
    /* margin-right: 18px !important; */
    /* background: red; */
}
.body-main {
    min-height: calc(100% - 30px);
    display: flex;
}
.menu-active {
    background-color: #f2f2f2 !important;
    border-radius: 8px !important;
}

.v-list-item__title {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
}
.menu-principal .v-list-item__icon {
    margin-left: 0 !important;
    min-width: 24px !important;
    margin-right: 0 !important;
}
.v-list-item__content {
    padding: 0 10px !important;
}
.v-list-item__title {
    font-weight: normal !important;
}
.meu-perfil {
    font-size: 0.9rem !important;
    color: #808080;
    line-height: 0.9rem;
}
.v-list-group__items {
    border-top: 1px solid #eaeaea;
    padding-top: 12px;
}
.v-btn {
    font-weight: normal;
    text-transform: none;
    box-shadow: none;
    /* font-size: 1.1rem !important; */
    /* min-height: 40px; */
    /* 
  border-radius: 6px; */
}
.menu-principal.v-navigation-drawer--open .v-list {
    padding: 1rem;
}
.menu-principal.v-navigation-drawer--mini-variant .v-list {
    padding: 0.5rem;
}
.v-app-bar .v-toolbar__content {
    box-shadow: 0 1px 18px #00000020 !important;
}
.perfil-card {
    margin-top: 28px;
    padding: 10px;
    background: red;
    position: relative;
    /* box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4); */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 12px !important;
}
.perfil-card::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 1.5em;
    top: 2px;
    left: 50%;
    box-sizing: border-box;
    border-radius: 6px;

    border: 1em solid black;
    border-color: transparent transparent #fff #fff;

    transform-origin: 0 0;
    transform: rotate(135deg);

    box-shadow: -3px 3px 3px 0 #f2f2f2;
}
/* Logo trade+ */
.menu-principal .v-navigation-drawer__content {
    height: auto !important;
}
.menu-principal .v-navigation-drawer__append {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}
.text-center input {
    text-align: center !important;
}
.h-100 {
    height: 100% !important;
}

.br-15 {
    border-radius: 15px !important;
}
.brt-15 {
    border-radius: 0 15px 15px 15px !important;
}
.text-center {
    text-align: center !important;
}
.table-footer {
    background-color: #f2f2f2 !important;
    border-top: thin solid #eaeaea !important;
    border-top-width: thin;
    border-top-style: solid;
    border-top-color: #eaeaea;
}
.table-footer td:nth-child(even) {
    background-color: #f8f8f8 !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
        .v-data-table__empty-wrapper
    ) {
    background-color: #f8f8f8;
}

.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid #eaeaea;
}
.select .v-select__selections {
    padding: 0 !important;
}

/* 
::-webkit-scrollbar {
  width: 9px;
  height: 12px;
  padding: 10px;
  
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
      margin: 3px;
           border-radius: 15px;
             margin-left: 10px;
  left: 10px;

}

::-webkit-scrollbar-thumb {
  background:  var(--v-primary-base); 
    border-radius: 15px;
    opacity: 0.8;
     margin-left: 10px;

    -webkit-transition: all .5s ease-in-out;
}


::-webkit-scrollbar-thumb:hover {
   opacity: 1;
} */

/* Input cinza 2 */
.page-title {
    color: var(--v-primary-base);
    font-weight: 500;
    font-size: 1.6rem;
}
.input-cinza2 fieldset {
    background-color: #f2f2f2;
    border: none;
    color: #808080 !important;
}

.input-cinza.select fieldset {
    /* background: blue !important; */

    /* transition: border 3s ease-in-out; */
}
.input-cinza.select fieldset {
    /* transition: border 3s ease-in-out; */
}
.input-cinza input {
    color: #808080 !important;
}

.input-cinza2.select.v-select--is-menu-active fieldset {
    background: #fff !important;
}

.input-cinza2.select.v-select--is-menu-active .v-input__slot {
    border-bottom: none !important;
    border-radius: 8px 8px 0 0 !important;
    transition: all 0.5s solid !important;
}

.input-cinza2.v-input--is-label-active fieldset {
    background: #fff;
    border: 1px solid #ccc;
}
.input-cinza2 .v-text-field__suffix {
    color: #808080 !important;
}
.input-cinza2.v-input--is-focused .v-input__slot {
    border: 2px solid var(--v-primary-base) !important;
}
.input-cinza2.v-text-field--outlined.v-input--dense.v-input--is-focused
    .v-label--active {
    opacity: 0;
}

.verao-dark {
    background-color: #f2b36c;
}
.verao-light {
    background-color: #e8ad6a;
}
.inverno-light {
    background-color: #1190b9;
}
.inverno-dark {
    background-color: #0d8ab0;
}
.inverno {
    background-color: #0d9ac7;
}
.verao {
    background-color: #ffc07a;
}

.th-separador,
.th-separador {
    border-left: 0;
    border-right: 1px solid;
    border-image: linear-gradient(
        to bottom,
        transparent 20%,
        #1a796e 25%,
        #1a796e 75%,
        transparent 80%
    );
    border-image-slice: 1;
}
.dark.th-separador {
    border-image: linear-gradient(
        to bottom,
        transparent 20%,
        #0f534b 25%,
        #0f534b 75%,
        transparent 80%
    );
    border-image-slice: 1;
}
.verao.th-separador,
.verao-light.th-separador,
.verao-dark.th-separador {
    border-image: linear-gradient(
        to bottom,
        transparent 20%,
        #d8a063 25%,
        #d8a063 75%,
        transparent 80%
    );
    border-image-slice: 1;
}
.inverno.th-separador,
.inverno-light.th-separador,
.inverno-dark.th-separador {
    border-image: linear-gradient(
        to bottom,
        transparent 20%,
        #0c718f 25%,
        #0c718f 75%,
        transparent 80%
    );
    border-image-slice: 1;
}
.min-width-100 {
    min-width: 100px;
}
.min-width-120 {
    min-width: 120px;
}
.min-width-150 {
    min-width: 150px;
}
.min-width-200 {
    min-width: 200px;
}
.min-h-100 {
    min-height: 100%;
}
h2 {
    font-size: 1.3rem !important;
}
.highcharts-title {
    fill: #000 !important;
    font-weight: normal;
    font-size: 1.3rem !important;
    font-family: 'TT Norms Pro' !important;
}

.v-application--is-ltr
    .v-list--dense.v-list--nav
    .v-list-group--no-action
    > .v-list-group__items
    > .v-list-item {
    padding-left: 32px !important;
}

.select-outline {
    background: #fff;
    border: 2px solid #fff;
    border-radius: 0 0 8px 8px !important;
    margin-top: -1px;
    box-shadow: none !important;
    width: 1px;
    transition: border 3s ease;
    border-top: none;
    /* z-index: 10 !important; */
}

.select-outline.menuable__content__active {
    border: 2px solid var(--v-primary-base);
    border-top: none;
    transition: border 0.3s ease;
}
.filtros-oportunidade .v-expansion-panel-content__wrap {
    padding: 0;
}

.customInfoBoxOport {
    margin-top: -3px;
    margin-left: 2px;
    z-index: 7 !important;
}
.input-button .v-input__append-inner {
    margin-top: 0 !important;
}
.input-button .v-input__append-inner .v-icon {
    background: var(--v-primary-base);
    height: 40px;
    padding: 0 10px;
    border-radius: 0 6px 6px 0;
}
.input-button .v-input__slot {
    padding-right: 0px !important;
}
.select-outline .v-list-item__title,
.v-list-item__subtitle {
    text-overflow: unset;
    white-space: normal;
    padding: 10px 0;
}

.v-application--is-ltr .select-outline .v-list-item__action:first-child,
.v-application--is-ltr .select-outline .v-list-item__icon:first-child {
    margin-right: 4px;
}

.select-outline .v-list-item__content {
    padding: 10px 0px;
}
.select-outline .v-list-item__title,
.v-list-item__subtitle {
    text-overflow: unset;
    white-space: normal;
    padding: 10px 0;
}
.table-footer-prepend {
    margin-top: -58px;
    height: 58px;
}

.dropZone {
    width: 100%;
    height: 90px;
    position: relative;
    color: #808080;
}

.dropZone:hover {
    /* border: 2px solid #2e94c4; */
}

.dropZone:hover .dropZone-title {
    color: #1975a0;
}

.dropZone-info {
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50px;
    transform: translate(0, -50%);
    padding: 10px 15px;
    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 6px;
}

.dropZone-title {
    color: #808080;
    margin-top: 20px;
}

.dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: blue;
}

.dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropZone-over .dropZone-info {
    border: 2px dashed #ccc;
}

.dropZone-uploaded {
    width: 100%;
    height: 50px;
    margin: 20px 0;
    padding: 10px 15px;

    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 6px;
    position: relative;
}

.dropZone-uploaded-info {
    display: flex;
    align-items: center;
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.removeFile:hover {
    color: red;
    cursor: pointer;
}
.clickable {
    cursor: pointer;
    text-decoration: underline;
}
.instrucoes {
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 6px;
    color: #808080;
}
.instrucoes h4 {
    color: var(--v-primary-base);
    margin-bottom: 6px;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 0, 0.12);
}

.theme--light.v-text-field:not(.v-input--has-state):hover
    > .v-input__control
    > .v-input__slot:before {
    border-color: rgba(0, 0, 0, 0.32);
}
</style>

