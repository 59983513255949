<template>
    <div class="my-2">
        <v-expand-transition>
            <v-row no-gutters>
                <v-col md="4">
                    <div @click="alterarStatus(2)">
                        <Indicador
                            titulo="Ativações Ok"
                            :valor="indicadores.status_ok"
                            icone="mdi-check-circle"
                            color="success"
                            :class="{
                                active: id_status_planejamento && id_status_planejamento.includes(2),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alterarStatus(1)">
                        <Indicador
                            titulo="Ativações Pendentes"
                            :valor="indicadores.status_pendente"
                            icone="mdi-alert-circle"
                            color="warning"
                            :class="{
                                active: id_status_planejamento && id_status_planejamento.includes(1),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alterarStatus(3)">
                        <Indicador
                            titulo="Ativações Canceladas"
                            :valor="indicadores.status_cancelado"
                            icone="mdi-text-box-remove-outline"
                            color="error"
                            :class="{
                                active: id_status_planejamento && id_status_planejamento.includes(3),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alterarStatus(7)">
                        <Indicador
                            titulo="Ativações Realocadas"
                            :valor="indicadores.status_realocado"
                            icone="mdi-text-box-remove-outline"
                            color="error"
                            :class="{
                                active: id_status_planejamento && id_status_planejamento.includes(7),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alterarStatus(6)">
                        <Indicador
                            titulo="Ativações Enviadas"
                            :valor="indicadores.status_enviado"
                            icone="mdi-send-check"
                            color="error"
                            :class="{
                                active: id_status_planejamento && id_status_planejamento.includes(6),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alteraAtivacao('oferta-do-dia')">
                        <Indicador
                            titulo="Ofertas do dia"
                            :valor="indicadores['oferta-do-dia']"
                            icone="mdi-tag-multiple-outline"
                            color="warning"
                            :class="{
                                active: ativacao && ativacao.includes('oferta-do-dia'),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alteraAtivacao('produto-patrocinado-busca')">
                        <Indicador
                            titulo="Patrocinado e Autocomplete"
                            :valor="indicadores['produto-patrocinado-busca']"
                            icone="mdi-cash-multiple"
                            color="success"
                            :class="{
                                active: ativacao && ativacao.includes('produto-patrocinado-busca'),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alteraAtivacao('vitrine-patrocinada')">
                        <Indicador
                            titulo="Vitrine patrocinada"
                            :valor="indicadores['vitrine-patrocinada']"
                            icone="mdi-storefront-outline"
                            color="warning"
                            :class="{
                                active: ativacao && ativacao.includes('vitrine-patrocinada'),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click="alteraAtivacao('oferta-relampago')">
                        <Indicador
                            titulo="Ofertas Relâmpago"
                            :valor="indicadores['oferta-relampago']"
                            icone="mdi-flash"
                            color="error"
                            :class="{
                                active: ativacao && ativacao.includes('oferta-relampago'),
                            }"
                        />
                    </div>
                </v-col>

                <v-col md="4">
                    <div @click="alteraAtivacao('compre-junto')">
                        <Indicador
                            titulo="Compre junto"
                            :valor="indicadores['compre-junto']"
                            icone="mdi-cart-plus"
                            color="warning"
                            :class="{
                                active: ativacao && ativacao.includes('compre-junto'),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click='alteraAtivacao("vitrine-patrocinada-pdp")'>
                        <Indicador
                            titulo="Vitrine patrocinada PDP"
                            :valor="indicadores['vitrine-patrocinada-pdp']"
                            icone="mdi-storefront"
                            color="success"
                            :class="{
                                active: ativacao && ativacao.includes('vitrine-patrocinada-pdp'),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4">
                    <div @click='alteraAtivacao("com-arte")'>
                        <Indicador
                            titulo="Espacos com arte"
                            :valor="indicadores['com-arte']"
                            icone="mdi-contain"
                            color="success"
                            :class="{
                                active: ativacao && ativacao.includes('com-arte'),
                            }"
                        />
                    </div>
                </v-col>
                <v-col md="4"></v-col>
                <v-col md="4">
                    <div @click='alteraAtivacao("hotpage")'>
                        <Indicador
                            titulo="Hotpage"
                            :valor="indicadores['hotpage']"
                            icone="mdi-fire"
                            color="success"
                            :class="{
                                active: ativacao && ativacao.includes('hotpage'),
                            }"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-expand-transition>
    </div>
</template>

<script>
import Indicador from './_components/Indicador.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
export default {
    components: {
        Indicador,
    },
    props: ['indicadores', 'filtro'],
    computed: {
        ...mapFields('planejamento_ecommerce',[
            'filtro.id_status_planejamento',
            'filtro.ativacao',
        ]),
        // status: function () {
        //     return this.filtro?.filtro?.id_status_planejamento || []
        // },
        // ativacao: function () {
        //     return this.filtro?.filtro?.ativacao || []
        // },
    },
    data() {
        return {
            expand: true,
            expandIcon: 'mdi-chevron-up',
        }
    },
    methods: {
        ...mapActions('planejamento_ecommerce', ['setAtivacao','setStatus']),
        alterarStatus: function (status) {
            // if (this.status.includes(status)) {
            //     this.filtro.filtro.id_status_planejamento = this.status.filter(
            //         (s) => s != status
            //     )
            // } else {
            //     this.filtro.filtro.id_status_planejamento = [status]
            // }
            this.setStatus(status)
            this.filtro.filtrar()
        },
        alteraAtivacao: function (ativacao) {
            // if (this.ativacao.includes(ativacao)) {
            //     this.filtro.filtro.ativacao = this.ativacao.filter(
            //         (s) => s != ativacao
            //     )
            // } else {
            //     this.filtro.filtro.ativacao = [ativacao]
            // }
            this.setAtivacao(ativacao)
            this.filtro.filtrar()
        },
    },
}
</script>