<template>
    <v-dialog
        v-model="dialogForm"
        scrollable
        persistent
        content-class="dialog-pdv br-15"
    >
        <v-card>
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title>
                    {{ formTitulo }} - Etapa ({{ passo }} de 3)
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    color="#fff"
                    @click="dialogForm = false"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- <v-card-text class="pa-0"> -->
            <v-stepper v-model="passo" non-linear class="elevation-0 card-body">
                <v-stepper-header class="px-2">
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa1"
                        step="1"
                        >Cadastro de Acordo</v-stepper-step
                    >
                    <v-divider class="mx-2"></v-divider>

                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa2"
                        step="2"
                        >Seleção de Produtos</v-stepper-step
                    >
                    <v-divider class="mx-2"></v-divider>
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa3"
                        step="3"
                        >Resumo</v-stepper-step
                    >
                </v-stepper-header>
                <div class="etapas-conteudo">
                    <v-stepper-items class="min-h-100">
                        <v-stepper-content class="min-h-100" step="1">
                            <cadastro
                                ref="cadastro"
                                :usuarioTipo="usuarioTipo"
                                v-model="acordo"
                                @limpaProdutos="$refs.produto.limpaProdutos()"
                            ></cadastro>
                        </v-stepper-content>

                        <v-stepper-content class="min-h-100" step="2">
                            <produto ref="produto" v-model="acordo"></produto>
                        </v-stepper-content>

                        <v-stepper-content class="min-h-100" step="3">
                            <resumo ref="resumo" v-model="acordo"></resumo>
                        </v-stepper-content>
                    </v-stepper-items>
                </div>

                <div class="d-flex card-footer pa-4">
                    <v-btn
                        color="yellow"
                        width="130px"
                        :disabled="disabledVoltar"
                        @click="voltar"
                        class="new-button"
                        dark
                    >
                        <v-icon left>mdi-chevron-left-circle</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        width="130px"
                        @click="proximo"
                        class="new-button"
                        v-if="!acordo.fnLeitura || passo < 5"
                    >
                        {{ botao }}
                        <v-icon right>{{ botaoIco }}</v-icon>
                    </v-btn>
                </div>
            </v-stepper>
        </v-card>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 6"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-dialog v-model="dialogoErro" persistent width="90%">
            <v-card>
                <v-card-title class="subtitle-1 error white--text">
                    Erro(s) ao Salvar Acordo(s)
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-data-table
                        :headers="listaHeaderErro"
                        :items="listaErro"
                        :items-per-page="5"
                        :options.sync="paginationErro"
                        class="elevation-1 data-table-2"
                    ></v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <download-csv
                        :data="listaErro"
                        :labels="listaErroLabels"
                        name="erros.csv"
                        delimiter=";"
                    >
                        <v-btn color="primary">
                            <v-icon left>mdi-microsoft-excel</v-icon>
                            Exportar
                        </v-btn>
                    </download-csv>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="fecharErros()">
                        <v-icon left>mdi-close</v-icon>
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import Cadastro from './Cadastro.vue'
import Produto from './Produto.vue'
import Resumo from './Resumo.vue'

export default {
    name: 'abaFormulario',
    inject: ['listaCampos'],
    components: {
        Cadastro,

        Produto,
        Resumo,
    },
    data() {
        return {
            acordo: {
                mesInicial: null,
                mesFinal: null,
                id_espaco: 16485254,
                // espaco_macro: null,
                id_fornecedor: null,
                fornecedor: null,
                fornecedorDesconto: null,
                bandeira: null,
                // flag_teste: false,
                obs_departamento: null,
                obs_contrato: null,
                comentario: null,
                fnEditar: false,
                fnLeitura: false,
                preAcordo: false,
                id_acordo_title: 0,
            },
            usuarioTipo: 'rd',
            dialogForm: false,
            passo: 1,
            botao: 'Próximo',
            botaoIco: 'mdi-chevron-right',
            disabledVoltar: true,
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            paginationErro: {},
            dialogoErro: false,
            listaHeaderErro: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left' },
            ],

            listaErro: [],
            listaErroLabels: { msg: 'Descrição do Erro' },
            status: {},
            regra: {
                etapa1: [
                    () => {
                        if (this.passo > 1) {
                            return this.$refs.cadastro.validate() // validar etapa 1
                        }
                        return true //  valido
                    },
                ],

                etapa2: [
                    () => {
                        if (this.passo > 2) {
                            return this.$refs.produto.validate() // validar etapa 1
                        }
                        return true //  valido
                    },
                ],
                etapa3: [
                    () => {
                        return true //  valido
                    },
                ],
            },
        }
    },
    async provide() {
        return {
            listaCampos: await this.listaCampos,
        }
    },
    computed: {
        formTitulo() {
            let _idAcordo = this.acordo.id_acordo_title
            if (this.acordo.fnNovo) {
                return 'Novo Acordo'
            }
            return (
                'Acordo ' +
                _idAcordo +
                (this.acordo.fnLeitura ? ' - Somente Leitura' : '')
            )
        },
    },
    watch: {
        'acordo.preAcordo': function (value) {
            console.log(value)
            if (this.passo == 3 && value === true) {
                this.botao = 'Salvar'
                this.botaoIco = 'mdi-content-save'
            } else {
                this.botao = 'Próximo'
                this.botaoIco = 'mdi-chevron-right'
                this.acordo.visivel = null
            }
        },
        passo(valor) {
            if (valor == 1) {
                this.botao = 'Avançar'
                this.botaoIco = 'mdi-chevron-right-circle'
            } else if (valor == 2) {
                this.botao = 'Avançar'
                this.botaoIco = 'mdi-chevron-right-circle'
                this.carregaProduto()
            } else if (valor == 3) {
                this.botao = 'Salvar'
                this.botaoIco = 'mdi-check-circle'
                // this.resumoAcordo()
            }
            this.disabledVoltar = this.passo == 1 ? true : false
        },
    },

    methods: {
        getAcordo() {
            return this.acordo
        },

        async carregaProduto() {
            await this.$refs.produto.buscaProdutos(true)
        },
        getPeriodo(campo = 'quantidade') {
            var mesAtual = new Date(this.acordo.mesInicial + '-15')
            var mesFinal = new Date(this.acordo.mesFinal + '-15')

            var periodo = []

            while (mesAtual <= mesFinal) {
                periodo.push(
                    campo +
                        '_' +
                        mesAtual.getFullYear() +
                        '_' +
                        (mesAtual.getMonth() + 1)
                )

                mesAtual.setMonth(mesAtual.getMonth() + 1)
            }

            return periodo
        },
        novo(usuarioTipo) {
            console.log('novo')
            this.acordo = {
                tipo_acordo: 'PDV',
                investimento_total: 0,
                mesInicial: null,
                mesFinal: null,
                id_espaco: 16485254,
                id_fornecedor: null,
                fornecedor: null,
                fornecedorDesconto: null,
                bandeira: ['DROGASIL', 'RAIA'],
                obs_departamento: null,
                obs_contrato: null,
                comentario: null,
                fnNovo: true,
                fnEditar: false,
                fnLeitura: false,
                fnReplicar: false,
                id_acordo_title: 0,
                produtos: [],
            }
            this.usuarioTipo = usuarioTipo
            this.dialogForm = true
            this.passo = 1
            this.disabledVoltar = true
            this.botao = 'Próximo'
            this.botaoIco = 'mdi-chevron-right'

            try {
                this.$refs.cadastro.limpaCadastro()
                this.$refs.cadastro.resetValidation()
                this.$refs.produto.limpaProdutos()
            } catch (error) {
                //console.log(error)
            }
            this.acordo.mesInicial = this.$moment().format('YYYY-MM')
            this.acordo.mesFinal = this.$moment().format('YYYY-MM')

            this.$gtag.pageview({ page_title: `Novo Acordo` })

            this.$gtag.screenview({
                app_name: 'RD_Trade',
                screen_name: 'Novo_Acordo',
            })
        },
        async editar(item, fnLeitura = false) {
            this.dialogForm = true
            this.passo = 1
            this.disabledVoltar = true
            this.botao = 'Próximo'
            this.botaoIco = 'mdi-chevron-right'

            this.carregando = true

            try {
                this.$refs.cadastro.limpaCadastro()
                this.$refs.cadastro.resetValidation()

                this.$refs.produto.limpaProdutos()
            } catch (error) {
                //console.log(error)
            }

            // const data = {
            //     id_acordo: item.id_acordo,
            // }

            const response = await this.$http().post('/negociacaoComercial/lista', {
                data: {
                    id_negociacao_comercial: [item.id_negociacao_comercial],
                },
            })
            var acordo = response.data.lista[0]
            this.status = acordo.id_status

            this.acordo = {
                negociacao_comercial: acordo.negociacao_comercial,
                id_negociacao_comercial: acordo.id_negociacao_comercial,
                mesInicial: acordo.mes_acordo,
                mesFinal: acordo.termino,
                id_lote: acordo.id_lote,
                id_espaco: acordo.id_espaco,
                id_fornecedor: acordo.id_fornecedor,
                fornecedor: acordo.fornecedor,
                tipo_acordo: acordo.id_acordo_pdv ? 'PDV' : 'E-commerce',
                bandeira: [acordo.bandeira],
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                comentario: acordo.comentario,
                fnEditar: true,
                fnLeitura: fnLeitura,
                fnReplicar: false,
                fnNovo: false,
                idStatus: acordo.id_status,
                id_acordo_title: acordo.id_negociacao_comercial,
                investimento_total: acordo.investimento_total,
                parcelas_pagamento: acordo.parcelas_pagamento,
                forma_pagamento: acordo.forma_pagamento,
                id_categoria_produto: acordo.id_categoria_produto,
                produtos: [],

            }

            await new Promise((resolve) => setTimeout(resolve, 500))
            await this.$refs.produto.limpaProdutos();
            await this.carregaProduto()
            this.carregando = false
        },

        async replicarAcordo(item, tipoReplicacao) {
            this.dialogForm = true
            this.passo = 1
            this.disabledVoltar = true
            this.botao = 'Próximo'
            this.botaoIco = 'mdi-chevron-right'

            this.carregando = true

            try {
                this.$refs.cadastro.limpaCadastro()
                this.$refs.cadastro.resetValidation()

  
                this.$refs.produto.limpaProdutos()
            } catch (error) {
                console.log(error)
            }
            const response = await this.$http().post('/negociacaoComercial/lista', {
                data: {
                    id_negociacao_comercial: [item.id_negociacao_comercial],
                },
            })
            var acordo = response.data.lista[0]
            this.status = acordo.status

            this.acordo = {
                negociacao_comercial: acordo.negociacao_comercial,
                id_negociacao_comercial: acordo.id_negociacao_comercial,
                mesInicial: acordo.mes_acordo,
                mesFinal: acordo.termino,
                id_lote: acordo.id_lote,
                id_espaco: acordo.id_espaco,
                id_fornecedor: acordo.id_fornecedor,
                fornecedor: acordo.fornecedor,
                tipo_acordo: acordo.id_acordo_pdv ? 'PDV' : 'E-commerce',
                bandeira: [acordo.bandeira],
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                comentario: acordo.comentario,
                fnEditar: false,
                fnLeitura: false,
                fnReplicar: true,
                fnNovo: true,
                idStatus: acordo.id_status,
                id_acordo_title: acordo.id_negociacao_comercial,
                investimento_total: acordo.investimento_total,
                parcelas_pagamento: acordo.parcelas_pagamento,
                forma_pagamento: acordo.forma_pagamento,
                id_categoria_produto: acordo.id_categoria_produto,
                produtos: [],

            }

            await new Promise((resolve) => setTimeout(resolve, 500))
            await this.$refs.produto.limpaProdutos();
            await this.carregaProduto()
            this.carregando = false

    
        },
        proximo() {
            if (this.passo == 3) {
                return this.salvarAcordo()
            } else {
                this.passo++
            }
        },
        voltar() {
            this.passo--
        },
        async salvarAcordo() {
            if (
                !this.$refs.cadastro.validate() ||
                !this.$refs.produto.validate()
            ) {
                return this.$dialog.notify.error('Formulário com erros', {
                    position: 'top-right',
                    timeout: 5000,
                })
            }

            this.carregando = !this.carregando

            const data = {}
            data.acordo = this.acordo
            data.produto = this.acordo.produtos.map((p) => p.id_produto)

            console.log(data)
            let response = {}
            if (data.acordo.fnEditar) {
                response = await this.$http().post(
                    '/negociacaoComercial/editar',
                    data
                )
            } else {
                response = await this.$http().post(
                    '/negociacaoComercial/salvar',
                    data
                )
            }

            this.$emit('filtrar')

            if (response.data.success) {
                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.message

                setTimeout(() => {
                    this.alerta = false
                    this.carregando = false
                    this.dialogForm = false
                }, 1000)
            } else {
                if (response.data.message) {
                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = response.data.message

                    this.carregando = false
                } else {
                    this.dialogoErro = true
                    this.listaErro = response.data.erros

                    this.paginationErro.page = 1
                }
            }
        },
        fecharErros() {
            this.dialogoErro = false
            this.carregando = false
            this.dialogForm = false
        },
        // resumoAcordo() {
        //     const resumo = {
        //         investimento: this.acordo.investimento,
        //         produtos: this.acordo.produtos.length,
        //     }
        // },

        statusAcesso() {
            if (this.acordo.fnLeitura) {
                return
            }

            var passoProcesso = [
                null,
                'cadastro',
                'filial',
                'desconto',
                'produto',
                'planograma',
            ]

            var semAcesso = false

            if (this.acordo.fnEditar) {
                semAcesso = !this.status['editar_' + passoProcesso[this.passo]]

                if (semAcesso) {
                    this.alerta = true
                    this.alertaColor = 'info'
                    this.alertaTimeout = 3000
                    this.alertaTexto =
                        'Esta etapa não pode ser editada no status atual do acordo.'
                }
            }

            setTimeout(
                () =>
                    this.$refs[passoProcesso[this.passo]].setSemAcesso(
                        semAcesso
                    ),
                200
            )
        },
    },
}
</script>
<style>
.card-econ {
    height: 100%;
    background: red;
}
.etapas-conteudo {
    height: 100%;
    overflow: auto;
}
.card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.card-footer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
}
.fix-flex {
    flex-grow: 0;
    flex-shrink: 1;
}
</style>
<style lang="scss">
.dialog-pdv {
    height: 100%;
    max-height: calc(100% - 50px) !important;
    .v-stepper__header {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
    }
    .v-stepper__content {
        box-shadow: unset !important;
    }
    .v-stepper__step {
        padding: 10px;
        margin: 15px;
        background-color: #f5f5f5;
        border-radius: 8px;
        min-width: 200px;
        justify-content: center;
        .v-stepper__label {
            color: #000 !important;
            font-weight: 500;
            font-size: 0.9rem;
        }
        .v-stepper__step__step {
            background-color: var(--v-primary-base) !important;
            height: 20px;
            width: 20px;
            min-width: 20px;
        }
        &:hover .v-stepper__label {
            text-shadow: unset !important;
        }
        &.v-stepper__step--error {
            .v-stepper__label {
                color: red !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                .v-icon {
                    color: red;
                    font-size: 24px;
                }
            }
        }
        &.v-stepper__step--active {
            background-color: var(--v-primary-base);
            color: white;
            &:hover {
                background-color: var(--v-primary-base);
            }
            .v-stepper__label {
                color: #fff !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                color: var(--v-primary-base) !important;
            }
        }
    }
}
</style>
