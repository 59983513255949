<template>
    <v-form ref="form">
        <div class="">
            <div class="mr-4 ">
                <v-combobox
                    :placeholder="placeholder"
                    v-bind:label="label"
                    :class="{
                        select: true,
                        'input-cinza': true,
                        active: menu1,
                    }"
                    v-bind:required="required"
                    v-bind:disabled="disabled"
                    :menu-props="menuProps"
                    v-model="cmpValue"
                    outlined
                    validate-on-blur
                    dense
                    multiple
                    deletable-chips
                    :delimiters="[',']"
                    small-chips
                    @change="delimit"
                    :rules="[required ? idTradeRegra : true]"
                ></v-combobox>
            </div>
        </div>
    </v-form>
</template>
 
 <script>
export default {
    name: 'SKUInput',
    model: { prop: 'value', event: 'input' },

    props: [
        'value',
        'rules',
        'min',
        'max',
        'placeholder',
        'label',
        'required',
        'disabled',
        'change',
        'prepend-icon',
        'firstDayOfWeek',
        'produtosServidor',
        'checkProduto'
        
    ],
    data() {
        return {
            mes: null,
            menu1: false,
            dialogoIdTrade: false,
            arquivoSku: false,

            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            idTradeRegra (value)  {
                return true
            },
        }
    },
    requerido() {
        return this.required
    },
    computed: {
        cmpValue: {
            get: function () {
                return this.value
            },
            set: function (newValue) {
                this.$emit('input', newValue)
            },
        },
        
    },
    methods: {
        delimit(v) {
            return true
            // const reducer = (a, e) => [...a, ...e.split(/[,]+/)]
            // var candidate = [...new Set(v.reduce(reducer, []))]
            // console.log({
            //     required:this.required,
            //     candidate,
            //     cmpValue: this.cmpValue,
            //     produtosServidor: this.produtosServidor
            // })
            // console.log('this.filtroProdutos',this.filtroProdutos)
            // if (this.required || this.checkProduto) {
            //     this.cmpValue = candidate.filter(item => {
            //         let encontrado = false
            //         for (const produto of this.produtosServidor) {
            //             if (produto.codigo == item) {
            //                 console.log(produto.codigo, item)
            //                 encontrado = true
            //             }
            //         }
            //         return encontrado
            //     })
            //     console.log(this.cmpValue )
            // }

        },
        desvalidar() {
            this.$refs.form.resetValidation()
        },
        validar() {
            // console.log('passou aqui',this.required)
            return this.$refs.form.validate() 
        },
    },
}
</script>
 
 <style>
.input-cinza.active .v-input__slot {
    border-bottom: none;
}

.input-cinza.active fieldset legend {
    width: 0 !important;
}

.input-mes .v-date-picker-header__value button {
    font-weight: 500;
    color: var(--v-primary-base) !important;
}

.input-mes,
.input-mes .v-picker__body {
    width: 100% !important;
}
.input-mes .v-btn__content {
    text-transform: capitalize;
}
.input-mes input {
    text-transform: uppercase !important;
}
.input-mes .theme--light.v-date-picker-table th {
    color: #ccc !important;
}
.input-mes .v-date-picker-table--date td {
    color: #ccc !important;
    padding: 0 !important;
}
</style>