var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3"},[_c('v-expand-transition',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(1)}}},[_c('Indicador',{class:{
                            active: _vm.status.includes(1),
                        },attrs:{"titulo":"Aguardando Aprovação Trade","valor":_vm.indicadores[1],"icone":"$alertCircleCheckOutline"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(29)}}},[_c('Indicador',{class:{
                            active: _vm.status.includes(29),
                        },attrs:{"titulo":"Espaço Reservado","valor":_vm.indicadores[29],"icone":"$circleSlice"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(15)}}},[_c('Indicador',{class:{
                            active: _vm.status.includes(15),
                        },attrs:{"titulo":"Aguardando Gestão","valor":_vm.indicadores[15],"icone":"$documentAlertOutline"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(16)}}},[_c('Indicador',{class:{
                            active: _vm.status.includes(16),
                        },attrs:{"titulo":"Contratos Emitidos","valor":_vm.indicadores[16],"icone":"$documentCheck"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(28)}}},[_c('Indicador',{class:{
                            active: _vm.status.includes(28),
                        },attrs:{"titulo":"Acordos Cancelados","valor":_vm.indicadores[28],"icone":"$documentRemove"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(2)}}},[_c('Indicador',{class:{
                            active: _vm.status.includes(2),
                        },attrs:{"titulo":"Acordos Reprovados","valor":_vm.indicadores[2],"icone":"$documentRemove"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }