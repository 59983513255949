<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col  v-if="detalhesCampo('projeto')"  cols="4" class="py-0">
                <v-text-field
                    label="Projeto"
                    class="input-cinza"
                    v-model="value.projeto"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col  v-if="detalhesCampo('link')"  cols="4" class="py-0">
                <v-text-field
                    label="Link"
                    class="input-cinza"
                    v-model="value.link"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col   v-if="detalhesCampo('id_rd')"  cols="4" class="py-0">
                <v-text-field
                    label="ID"
                    class="input-cinza"
                    v-model="value.id_rd"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col  v-if="detalhesCampo('posicao')"  cols="4" class="py-0">
                <v-text-field
                    label="Posição"
                    class="input-cinza"
                    v-model="value.posicao"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col  v-if="detalhesCampo('sku_mix')"  cols="4" class="py-0">
                <v-text-field
                    label="SKU Mix"
                    class="input-cinza"
                    v-model="value.sku_mix"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col  v-if="detalhesCampo('skus')"  cols="4" class="py-0">
                <sku-input
                    ref="SKUs"
                    label="SKUs"
                    class="input-cinza"
                    v-model="value.skus"
                    outlined
                    dense
                    :produtosServidor="value.produtosServidor"
                    :filtroProdutos="value.id_fornecedor != 115779721"
                    :required="skuObrigatorio"
                    :checkProduto="value.id_fornecedor != 115779721 && skuObrigatorio"
                    :ignoraChecagemProduto="value.id_fornecedor == 115779721"
                    :regra="skuObrigatorio ? regra.skus : []"
                    
                ></sku-input>
            </v-col>
            <v-col  v-if="detalhesCampo('segmentacao_base')"  cols="4" class="py-0">
                <v-text-field
                    label="Segmentação Base"
                    class="input-cinza"
                    v-model="value.segmentacao_base"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col  v-if="detalhesCampo('sku_com_opcao_pdp')"  cols="4" class="py-0">
                <sku-input
                    label="SKUs com a opção (PDP)"
                    class="input-cinza"
                    v-model="value.sku_com_opcao_pdp"
                    outlined
                    dense
                    :required="false"

                ></sku-input>
            </v-col>
            <v-col  v-if="detalhesCampo('mix_sku_compre_junto')"  cols="4" class="py-0">
                <sku-input
                    label="Mix dos SKUs Compre Junto"
                    class="input-cinza"
                    v-model="value.mix_sku_compre_junto"
                    outlined
                    dense
                    :produtosServidor="value.produtosServidor"
                    :checkProduto="true"

                ></sku-input>
            </v-col>
            <v-col  v-if="detalhesCampo('segmentacao')"  cols="4" class="py-0">
                <v-text-field
                    label="Segmentacao"
                    class="input-cinza"
                    v-model="value.segmentacao"
                    outlined
                    dense
                    

                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-4" >
            
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    v-model="value.observacoes"
                    class="input-cinza"
                    dense
                    :counter="500"
                    :rules="regra.observacoes"
                    rows="3"
                    label="Observações"
                ></v-textarea>
            </v-col>
        </v-row>

        
        <!-- {{ detalhesCampo('ativacao') }} -->
    </v-form>
</template>
<script>
import useFiltros from '@/hooks/useFiltros'
var smartId = require("smart-id");
export default {
  setup() {
        const { isLoading, isError, isFetching, data, error, refetch } =
            useFiltros('/planejamentoEcommerce/listaCampos')
        return {
            isLoading,
            isError,
            isFetching,
            lista: data,
            error,
            refetch,
        }
    },
    props: ['value'],
    data() {
        return {
            mesAtual: this.$moment().format('YYYY-MM'),
            bandeiras: ['Raia', 'Drogasil'],
            devices: [
                { id: 1, nome: 'Desktop' },
                { id: 2, nome: 'Mobile' },
                { id: 3, nome: 'App' },
            ],
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            dialogoSku: false,
            regra: {
                observacoes: [
                    (v) => {
                        if (v && v.length > 500) {
                            return 'Máximo de 500 caracteres'
                        }
                        return true
                    },
                ],
                link: [(v) => !!v || "O campo link é obrigatório"],
                data_link: [(v) => !!v || "O campo data é obrigatório"],
                /// cria regra para sku baseado no campo skuObrigatorio
                skus: [(v) => !!v || "O campo SKU é obrigatório"],
                 
            },
            drag: false,
            dragOpcoes: false,
            bloquearEdicao: false,
            links: []
        }
    },
    computed: {
        listaLinks() {
            return this.links.map((item, index) => {
                item.ordem = index;
                return item;
            });
        },        
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        listaVitrines() {
            if (this.value.vitrine && this.value.vitrine.length) {
                return this.value.vitrine
            }
            return []
        },
        skuObrigatorio () {
            // if (this.value.id_fornecedor == 115779721) return false
            // if ([23,31,33,34].includes(this.value.id_ativacao) ) return false 
            if ([32].includes(this.value.id_ativacao) ) return false 
            // if (this.value.id_area == 2 || this.value.id_area == 3 || this.value.id_area == 1 || this.value.id_area == 6) return false
            if (this.value.id_area == 1 && this.value.id_marca && this.value.id_marca[0] == 2) return false
      
            return true 
        },
        campos() {
            if (!this.lista) return []

            const id_ativacao = this.value.id_ativacao
            const ativacao = this.lista.ativacoes.find(
                (ativacao) => ativacao.value == id_ativacao
            )

            /// Exception for vitrine-patrocinada
            if (
                ativacao &&
                ativacao.text.startsWith('vitrine-patrocinada') &&
                ativacao.text != 'vitrine-patrocinada-pdp'
            ) {
                return this.lista.campos['vitrine-patrocinada']
            }

            if (ativacao && this.lista.campos[ativacao.text]) {
                return this.lista.campos[ativacao.text]
            } else {
                return this.lista.campos['default']
            }
        },
    },
    methods: {
        removerLink(id) {
            for (const index of this.links.keys()) {
                if (this.links[index].id == id)
                    delete this.links.splice(index, 1);
            }
        },
        novoLink() {
            this.links.push({
                link: '',
                data_link: '',
                id: smartId.make(),
            })   
        },
        checkSkus() {
            // console.log(this.value.id_fornecedor)
        },
        validarPeriodoInicial(v) {
            if (this.value.mesFinal) {
                return this.$moment(v).isSameOrBefore(this.value.mesFinal)
            }
            return true
        },
        validarPeriodoFinal(v) {
            if (this.value.mes) {
                return this.$moment(v).isSameOrAfter(this.value.mes)
            }
            return true
        },
        periodoInicialChange(v) {
            if (this.value.mesFinal) {
                if (this.$moment(v).isAfter(this.value.mesFinal)) {
                    this.value.mesFinal = v
                }
            }
        },
        delimit(v) {
            const reducer = (a, e) => [...a, ...e.split(/[,]+/)]
            this.value.vitrine = [...new Set(v.reduce(reducer, []))]
        },
        detalhesCampo(campo) {
            if (this.lista) {
                return this.campos.find((c) => c.campo == campo)
            }
        },
        periodoFinalChange(v) {
            if (this.value.mes) {
                if (this.$moment(v).isBefore(this.value.mes)) {
                    this.value.mes = v
                }
            }
        },
        
        validate() {
            var valido = true
            // console.log('this.skuObrigatorio',this.skuObrigatorio)
            if (this.value.id_fornecedor == 115779721) {
                if (this.detalhesCampo('skus')) {
                    this.$refs.SKUs.desvalidar()
                }  

            }

            if (  this.skuObrigatorio || this.skuObrigatorioPdp) {
                console.log('this.$refs.SKUs.validar()',this.$refs.SKUs.validar())
                if (!this.$refs.SKUs.validar()) {
                    console.log('retornou false')
                    return false
                }
            } else {

                // console.log(this.$refs, 'refs',_.has(this.$refs,'SKUs'))
                if (this.detalhesCampo('skus')) {
                    this.$refs.SKUs.desvalidar()
                }    
            }
            return valido
        },
    },
}
</script>
