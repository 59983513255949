<template>
    <v-container fluid>
        <v-card class="px-6 py-3 br-15 w-100" elevation="0">
            <v-form ref="formEnvio">
                <v-row>
                    <v-col class="d-flex" cols="6">
                        <v-toolbar-title class="page-title"
                            >Planograma Móvel dermo
                            <v-alert class="mt-2" text type="info"
                                >Os planogramas refletem para as duas
                                bandeiras</v-alert
                            >
                        </v-toolbar-title>
                        <div class="ma-1"></div>
                    </v-col>
                    <v-col cols="1" class="pb-0">
                        <v-btn
                            class="new-button w-100"
                            color="red"
                            @click="dialogoErros = true"
                            v-if="erros.length > 0"
                        >
                            Erros
                        </v-btn>
                    </v-col>

                    <v-col cols="3" class="pb-0">
                        <MesInput
                            label="Mes"
                            v-model="mes"
                            :rules="regra.mes"
                            :min="mesAtual"
                            :change="mudouMes"
                            required
                        ></MesInput>
                    </v-col>
                    <v-col cols="2" class="pb-0">
                        <v-btn
                            class="new-button w-100"
                            color="primary"
                            @click="enviarPlanogramas"
                            :disabled="this.espacoGrupoForms.length == 0"
                        >
                            <v-icon left>mdi-upload</v-icon> Enviar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <v-row>
                <v-col cols="12" class="pb-0">
                    <v-row>
                        <v-col cols="8" class="pb-0">
                            <v-autocomplete
                                label="Espaço"
                                v-model="valueEspacoGrupo"
                                :items="espacoGrupo"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            valueEspacoGrupo.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            valueEspacoGrupo.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            valueEspacoGrupo.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4" align="end">
                            <v-btn
                                class="w-100 new-button"
                                color="primary"
                                @click="adicioneCubo"
                            >
                                <v-icon left>mdi-plus-thick</v-icon> Planogramas
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row
                        v-for="espacoGrupoForm in espacoGrupoForms"
                        :key="espacoGrupoForm.value"
                    >
                        <v-col cols="12" class="pb-8">
                            <v-data-table
                                :headers="headersEspacoGrupo"
                                :items="espacoGrupoForm"
                                class="elevation-0 tabela"
                                :disable-pagination="true"
                                :disable-items-per-page="true"
                                :disable-filtering="true"
                                :hide-default-footer="true"
                            >
                                <template v-slot:[`item.planograma`]="{ item }">
                                    <div v-if="!item.planograma">
                                        <input
                                            :ref="
                                                'grupo' +
                                                '|' +
                                                item.grupo_value +
                                                '|' +
                                                item.cluster_value
                                            "
                                            name="files[]"
                                            class="d-none"
                                            @dragenter="dragging = true"
                                            @dragleave="dragging = false"
                                            accept="image/*"
                                            type="file"
                                            @change="
                                                onChange(
                                                    $event,
                                                    'grupo' +
                                                        '|' +
                                                        item.grupo_value +
                                                        '|' +
                                                        item.cluster_value
                                                )
                                            "
                                        />
                                        <v-btn
                                            color="primary"
                                            class="new-button mr-2"
                                            @click="
                                                openFileDialog(
                                                    'grupo' +
                                                        '|' +
                                                        item.grupo_value +
                                                        '|' +
                                                        item.cluster_value
                                                )
                                            "
                                        >
                                            <v-icon left>mdi-upload</v-icon>
                                            buscar planograma...
                                        </v-btn>
                                    </div>
                                    <div v-else class="dropZone-uploaded">
                                        <div
                                            class="dropZone-uploaded-info d-flex justify-center"
                                        >
                                            <span>
                                                {{ item.planograma.name }}
                                            </span>
                                            <span
                                                class="removeFile ml-3"
                                                @click="
                                                    removeFileGrupos(
                                                        'grupo' +
                                                            '|' +
                                                            item.grupo_value +
                                                            '|' +
                                                            item.cluster_value
                                                    )
                                                "
                                            >
                                                <v-icon
                                                    size="18"
                                                    color="#8d082e"
                                                    >mdi-close-circle</v-icon
                                                >
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- <v-col cols="6" class="pb-0">
                    <v-row>
                        <v-col cols="8" class="pb-0">
                            <v-autocomplete
                                label="Espaço"
                                v-model="valueFilaUnica"
                                :items="filasUnica"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            valueFilaUnica.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            valueFilaUnica.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            valueFilaUnica.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4" align="end">
                            <v-btn
                                class="w-100 new-button"
                                color="primary"
                                @click="adicioneFila"
                            >
                                <v-icon left>mdi-plus-thick</v-icon> Fila Única
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row
                        v-for="filasUnicaForm in filasUnicaForms"
                        :key="filasUnicaForm.value"
                    >
                        <v-col cols="12" class="pb-8">
                            <v-data-table
                                :headers="headersEspacoFila"
                                :items="filasUnicaForm"
                                class="elevation-0 tabela"
                                :disable-pagination="true"
                                :disable-items-per-page="true"
                                :disable-filtering="true"
                                :hide-default-footer="true"
                            >
                                <template v-slot:[`item.planograma`]="{ item }">
                                    <div v-if="!item.planograma">
                                        <input
                                            :ref="
                                                'fila' +
                                                '|' +
                                                item.fila_value +
                                                '|' +
                                                item.cluster_value
                                            "
                                            name="files[]"
                                            class="d-none"
                                            @dragenter="dragging = true"
                                            @dragleave="dragging = false"
                                            accept="image/*"
                                            type="file"
                                            @change="
                                                onChange(
                                                    $event,
                                                    'fila' +
                                                        '|' +
                                                        item.fila_value +
                                                        '|' +
                                                        item.cluster_value
                                                )
                                            "
                                        />
                                        <v-btn
                                            color="primary"
                                            class="new-button mr-2"
                                            @click="
                                                openFileDialog(
                                                    'fila' +
                                                        '|' +
                                                        item.fila_value +
                                                        '|' +
                                                        item.cluster_value
                                                )
                                            "
                                        >
                                            <v-icon left>mdi-upload</v-icon>
                                            buscar planograma...
                                        </v-btn>
                                    </div>
                                    <div v-else class="dropZone-uploaded">
                                        <div
                                            class="dropZone-uploaded-info d-flex justify-center"
                                        >
                                            <span>
                                                {{ item.planograma.name }}
                                            </span>
                                            <span
                                                class="removeFile ml-3"
                                                @click="
                                                    removeFileFilas(
                                                        'fila' +
                                                            '|' +
                                                            item.fila_value +
                                                            '|' +
                                                            item.cluster_value
                                                    )
                                                "
                                            >
                                                <v-icon
                                                    size="18"
                                                    color="#8d082e"
                                                    >mdi-close-circle</v-icon
                                                >
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col> -->
            </v-row>
        </v-card>

        <v-dialog v-model="dialogoUpload" width="40%">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Status dos uploads
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters class="mx-2 mt-8" v-show="progresso">
                        <v-col cols="12" class="px-2">
                            {{ labelProgresso }}
                        </v-col>
                        <v-col cols="12" class="px-2">
                            <v-progress-linear
                                v-model="indexProgresso"
                                height="25"
                                :color="'primary'"
                            >
                                <span class="font-weight-bold white--text"
                                    >{{ Math.ceil(indexProgresso) }}%</span
                                >
                            </v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6"> </v-col>
                            </v-row>
                            <v-data-table
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoErros" width="40%">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Erros dos uploads
                </v-card-title>
                <v-card-text>
                    <v-simple-table
                        fixed-header
                        height="300px"
                        class="elevation-0 data-table-2"
                    >
                        <template v-slot:default>
                            <!-- <thead>
                            <tr>
                                <th class="text-left">
                                    Mensagem
                                </th>
                            </tr>
                        </thead> -->
                            <tbody>
                                <tr v-for="erro in erros" :key="erro.msg">
                                    <td>{{ erro.msg }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoErros = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
        <v-dialog v-model="dialogConfirm" width="600">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Confirmação
                </v-card-title>
                <v-card-text class="mt-4">
                    Os planogramas estão sendo considerados para o mês de
                    {{ mostreMesAno() }}, deseja salvar?
                    <br />
                    <br />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dialogConfirm = false">
                        Não
                    </v-btn>
                    <v-btn color="success" @click="procedaEnviarPlanogras">
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'planogramas_clustes',
    data() {
        return {
            mes: this.$moment().format('YYYY-MM'),
            mesAtual: this.$moment().format('YYYY-MM'),
            regra: {
                mes: [
                    (v) => !!v || 'O campo mes é obrigatório',
                    (v) =>
                        (v && v >= this.mesAtual) ||
                        this.value.fnLeitura ||
                        'O período inicial deve ser igual ou posterior ao mês atual',
                ],
            },
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            alertClusterType: 'warning',
            dadosUpdateDay: [],
            progresso: true,
            dialogoUpload: false,
            dialogConfirm: false,
            dialogoErros: false,
            errorProgresso: true,
            paginationErros: {},
            headerErros: [{ text: 'Mensagem', value: 'msg', align: 'center' }],
            erros: [],
            errosLabels: {
                tipo: 'Tipo',
                linha: 'Linha',
                id_java: 'ID JAVA',
                codigo_rd: 'Código RD',
                quantidade: 'Quantidade',
                mes: 'Mês',
                msg: 'Descrição',
            },
            erro: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            carregando: false,
            dadosiniciais: {},
            espacoGrupo: [],
            filasUnica: [],
            indexProgresso: 0,
            labelProgresso: '',
            valueEspacoGrupo: [],
            valueFilaUnica: [],
            clusters: {
                1: 'Popular',
                6: 'Super Popular',
                3: 'Nobre',
                4: 'Super Nobre',
                2: 'Híbrida',
                5: 'Ampla',
            },
            meses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
            ],
            headersEspacoFila: [
                {
                    text: 'Espaço',
                    value: 'fila_espaco',
                    align: 'left',
                    class: 'th-separador',
                },
                {
                    text: 'Cluster',
                    value: 'cluster',
                    align: 'left',
                    class: 'th-separador',
                },
                {
                    text: 'Planograma',
                    value: 'planograma',
                    align: 'center',
                    class: 'th-separador',
                },
            ],
            headersEspacoGrupo: [
                {
                    text: 'Grupo espaço',
                    value: 'grupo_espaco',
                    align: 'left',
                    class: 'th-separador',
                },
                {
                    text: 'Cluster',
                    value: 'cluster',
                    align: 'left',
                    class: 'th-separador',
                },
                {
                    text: 'Planograma',
                    value: 'planograma',
                    align: 'center',
                    class: 'th-separador',
                },
            ],
            espacoGrupoForms: [],
            filasUnicaForms: [],
        }
    },
    methods: {
        mudouMes(mes) {
            this.busqueDadosIniciais(mes)
        },
        mostreMesAno() {
            let mes = this.$moment(this.mes, 'YYYY-MM')
            return (
                this.meses[mes.format('MM') - 1] + ' de ' + mes.format('YYYY')
            )
        },
        async enviarPlanogramas() {
            this.dialogConfirm = true
        },
        async procedaEnviarPlanogras() {
            this.erros = []
            if (this.espacoGrupoForms.length == 0) {
                return
            }
            var formData = null
            this.dialogConfirm = false
            this.dialogoUpload = true
            this.indexProgresso = 0
            let partes =
                100 /
                (Object.keys(this.filasUnicaForms).length)
            // for (var filaUnicaForm in this.filasUnicaForms) {
            //     formData = new FormData()
            //     for (var f of this.filasUnicaForms[filaUnicaForm]) {
            //         this.labelProgresso = 'Fila única:' + f.fila_espaco
            //         if (f.planograma)
            //             formData.append(
            //                 'files',
            //                 f.planograma,
            //                 f.fila_value +
            //                     '|' +
            //                     f.cluster_value +
            //                     '|' +
            //                     f.planograma.name
            //             )
            //     }
            //     formData.append('fila', f.fila_espaco)
            //     formData.append('mes', this.mes)
            //     let response = await this.$http().post(
            //         '/planogramaMD/salvarfila',
            //         formData
            //     )
            //     if (response.sucesso == 0) {
            //         this.erros.push(response.erros)
            //     }
            //     this.indexProgresso = this.indexProgresso + parseInt(partes)
            // }
            // this.filasUnicaForms = {}
            // this.filasUnica = this.clone(this.dadosiniciais.filasUnica)
            // this.indexProgresso = 0;
            // partes = 100 / (Object.keys(this.espacoGrupoForms)).length
            for (var espacoGrupoForm in this.espacoGrupoForms) {
                formData = new FormData()
                for (var p of this.espacoGrupoForms[espacoGrupoForm]) {
                    this.labelProgresso = 'Planograma:' + p.grupo_espaco
                    if (p.planograma)
                        formData.append(
                            'files',
                            p.planograma,
                            p.grupo_value +
                                '|' +
                                p.cluster_value +
                                '|' +
                                p.planograma.name
                        )
                }
                formData.append('grupo', p.grupo_espaco)
                formData.append('mes', this.mes)
                let response = await this.$http().post(
                    '/planogramaMD/salvargrupo',
                    formData
                )
                if (response.data.sucesso == 0) {
                    console.log('erros')
                    this.erros.push(...response.data.erros)
                } else {
                    console.log(parseInt(partes))
                }
                this.indexProgresso = this.indexProgresso + parseInt(partes)
            }
            this.espacoGrupoForms = {}
            this.espacoGrupo = this.clone(this.dadosiniciais.espacoGrupo)
            this.dialogoUpload = false
            this.alerta = true
            if (this.erros.length > 0) {
                this.alertaTexto = 'Houveram alguns erros na execução!'
                this.alertaColor = 'error'
            } else {
                this.alertaTexto = 'Execução realizada com sucesso!'
                this.alertaColor = 'success'
            }
        },
        salvar() {},
        handleEspacoGrupo() {
            console.log('handleEspacoGrupo')
        },
        adicioneFila() {
            if (this.valueFilaUnica.length > 0) {
                let filasUnica = this.clone(this.filasUnica)
                let filasUnicaEscolhido = filasUnica.filter((item) => {
                    let found = false
                    for (var i of this.valueFilaUnica) {
                        if (i == item.value) found = true
                    }
                    return found
                })
                for (var y of filasUnicaEscolhido) {
                    let itemsClustes = y.clusters.map((item) => {
                        return {
                            cluster_value: item,
                            cluster: this.clusters[item],
                            fila_value: y.value,
                            fila_espaco: y.text,
                            planograma: null,
                        }
                    })
                    this.filasUnicaForms = {
                        ...this.filasUnicaForms,
                        [y.value]: itemsClustes,
                    }
                    this.filasUnica = filasUnica.filter((item) => {
                        let found = false
                        let keys = _.keys(this.filasUnicaForms)
                        for (var x of keys) {
                            if (x == item.value) found = true
                        }
                        return !found
                    })
                }
                this.valueFilaUnica = []
            }
        },
        adicioneCubo() {
            if (this.valueEspacoGrupo.length > 0) {
                let espacoGrupo = this.clone(this.espacoGrupo)
                let espacoGrupoEscolhido = espacoGrupo.filter((item) => {
                    let found = false
                    for (var i of this.valueEspacoGrupo) {
                        if (i == item.value) found = true
                    }
                    return found
                })
                for (var y of espacoGrupoEscolhido) {
                    let itemsClustes = y.clusters.map((item) => {
                        console.log('cluster', item)
                        return {
                            cluster_value: item,
                            cluster: this.clusters[item],
                            grupo_value: y.value,
                            grupo_espaco: y.text,
                            planograma: null,
                        }
                    })
                    this.espacoGrupoForms = {
                        ...this.espacoGrupoForms,
                        [y.value]: itemsClustes,
                    }
                    this.espacoGrupo = espacoGrupo.filter((item) => {
                        let found = false
                        let keys = _.keys(this.espacoGrupoForms)
                        for (var x of keys) {
                            if (x == item.value) found = true
                        }
                        return !found
                    })
                }
                this.valueEspacoGrupo = []
            }
        },
        clone(object) {
            return JSON.parse(JSON.stringify(object))
        },
        openFileDialog(objectInput) {
            console.log(objectInput)
            // this.$nextTick(() => {
            this.$refs[objectInput][0].click()
            // })
        },
        onChange(e, slug) {
            console.log(slug)
            var files = e.target.files || e.dataTransfer.files
            console.log(files)

            if (!files.length) {
                this.dragging = false
                return
            }

            this.createFile(files[0], slug)
        },
        createFile(file, slug) {
            let slugPartes = slug.split('|')
            if (slugPartes[0] == 'grupo') {
                this.espacoGrupoForms[slugPartes[1]] = this.espacoGrupoForms[
                    slugPartes[1]
                ].map((item) => {
                    if (item.cluster_value == slugPartes[2] * 1) {
                        return {
                            ...item,
                            planograma: file,
                        }
                    } else {
                        return item
                    }
                })
            } else {
                this.filasUnicaForms[slugPartes[1]] = this.filasUnicaForms[
                    slugPartes[1]
                ].map((item) => {
                    console.log(
                        item.fila_value,
                        slugPartes[2],
                        item.fila_value == slugPartes[2] * 1
                    )
                    if (item.cluster_value == slugPartes[2] * 1) {
                        return {
                            ...item,
                            planograma: file,
                        }
                    } else {
                        return item
                    }
                })
                console.log('****', this.filasUnicaForms)
            }
            this.dragging = false
        },
        removeFileGrupos(slug) {
            let slugPartes = slug.split('|')
            this.espacoGrupoForms[slugPartes[1]] = this.espacoGrupoForms[
                slugPartes[1]
            ].map((item) => {
                if (item.cluster_value == slugPartes[2] * 1) {
                    return {
                        ...item,
                        planograma: null,
                    }
                } else {
                    return item
                }
            })
        },
        removeFileFilas(slug) {
            let slugPartes = slug.split('|')
            this.filasUnicaForms[slugPartes[1]] = this.filasUnicaForms[
                slugPartes[1]
            ].map((item) => {
                if (item.cluster_value == slugPartes[2] * 1) {
                    return {
                        ...item,
                        planograma: null,
                    }
                } else {
                    return item
                }
            })
        },
        async busqueDadosIniciais(mes) {
            this.carregando = true
            this.espacoGrupoForms = []
            this.filasUnicaForms = []
            const response = await this.$http().post(
                '/planogramaMD/dadosiniciais',
                { mes }
            )
            if (response.status != 200) {
                /// tratar erros de carregamento
                this.$dialog.notify.error('Erro ao carregar dados iniciais', {
                    position: 'top-right',
                    timeout: 5000,
                })
                this.carregando = false
                return
            }
            let allClusters = [1, 2, 3, 4, 5, 6]
            response.data.espacoGrupo = response.data.espacoGrupo.map(
                (item) => {
                    return {
                        ...item,
                        clusters: item.clusters.includes(7)
                            ? allClusters
                            : item.clusters,
                    }
                }
            )
            this.dadosiniciais = response.data
            this.espacoGrupo = this.clone(this.dadosiniciais.espacoGrupo)
            console.log('this.espacoGrupo', this.espacoGrupo)
            this.carregando = false
        },
    },
    async mounted() {
        this.busqueDadosIniciais(this.mes)
        return
    },
}
</script>
