<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="4" class="py-0">
                <v-select
                    label="Negociação Comercial"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="value.tipo_acordo"
                    :items="['PDV', 'E-commerce']"
                    :menu-props="menuProps"
                    :disabled="value.fnEditar"
                    @change="negociacaoChange()"
                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <MesInput
                    label="Período Inicial"
                    v-model="value.mesInicial"
                    :rules="regra.mesInicial"
                    :change="periodoInicialChange"
                    :min="mesAtual"
                    :disabled="value.fnEditar"
                    required
                ></MesInput>
            </v-col>
            <v-col cols="4" class="py-0">
                <MesInput
                    label="Período Final"
                    v-model="value.mesFinal"
                    :rules="regra.mesFinal"
                    :change="periodoFinalChange"
                    :min="mesAtual"
                    :disabled="value.fnEditar"
                    required
                ></MesInput>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Produto"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="id_produtoBusca"
                    :items="lista.produto"
                    :search-input.sync="buscaProdutoFornecedor"
                    :loading="isLoadingProduto"
                    @change="produtoChange()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    clearable
                >
                    <template v-slot:no-data>
                        <p
                            v-if="
                                !buscaProdutoFornecedor ||
                                (buscaProdutoFornecedor &&
                                    buscaProdutoFornecedor.length <= 4)
                            "
                            class="ml-4 mt-4"
                        >
                            Insira pelo menos 4 caracteres para listar os
                            produtos.
                        </p>
                        <p v-else-if="isLoadingProduto" class="ml-4 mt-4">
                            Buscando produtos...
                        </p>
                        <p v-else class="ml-4 mt-4">Nada encontrado</p>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-text-field
                    label="Fornecedor"
                    class="input-cinza"
                    v-model="value.fornecedor"
                    :rules="regra.fornecedor"
                    :disabled="value.fnEditar"
                    outlined
                    dense
                    readonly
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Bandeira"
                    outlined
                    multiple
                    dense
                    class="input-cinza"
                    v-model="value.bandeira"
                    :items="lista.bandeira"
                    :rules="regra.bandeira"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Espaço"
                    outlined
                    multiple
                    dense
                    class="input-cinza"
                    :items="lista.espaco"
                    :disabled="true"
                    v-model="value.id_espaco"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <vuetify-money
                    :ripple="false"
                    dense
                    outlined
                    label="Valor de investimento"
                    hide-details
                    v-model="value.investimento_total"
                    class="input-cinza"
                    :rules="regra.investimento_total"
                />
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Forma de pagamento"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="value.forma_pagamento"
                    :items="['Débito bancário', 'Abatimento']"
                    :menu-props="menuProps"
                    :rules="regra.forma_pagamento"
                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Número de parcelas"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="value.parcelas_pagamento"
                    :items="[
                        {
                            text: 'Única',
                            value: 1,
                        },
                        {
                            text: '2x',
                            value: 2,
                        },
                        {
                            text: '3x',
                            value: 3,
                        },
                    ]"
                    :menu-props="menuProps"
                    :rules="regra.parcelas"
                    required
                ></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    v-model="value.obs_departamento"
                    class="input-cinza"
                    dense
                    rows="3"
                    label="Observação Departamento"
                ></v-textarea>

                <v-textarea
                    outlined
                    v-model="value.obs_contrato"
                    class="input-cinza"
                    dense
                    rows="3"
                    label="Observação do Contrato"
                ></v-textarea>
            </v-col>
        </v-row>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
export default {
    inject: ['listaCampos'],
    props: ['value', 'usuarioTipo'],
    data() {
        return {

            acordo: {
                tipo_acordo: 'PDV',
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                fornecedor: null,
                bandeira: null,
                obs_departamento: null,
                obs_contrato: null,
                comentario: null,
                fnEditar: false,
                fnLeitura: false,
                espaco_macro: null,
            },
            lista: {
                bandeira: [],
                espaco: [],
            },
            id_produtoBusca: null,
            buscaProdutoFornecedor: null,
            isLoadingProduto: false,
            regra: {
                mesInicial: [
                    (v) => !!v || 'O campo período inicial é obrigatório',
                    (v) =>
                        (v && v >= this.mesAtual) ||
                        this.value.fnLeitura ||
                        'O período inicial deve ser igual ou posterior ao mês atual',
                ],
                mesFinal: [
                    (v) => !!v || 'O campo período final é obrigatório',
                    (v) =>
                        (v && v >= this.value.mesInicial) ||
                        'O período final deve ser igual ou posterior ao período inicial',
                ],
                espaco: [(v) => !!v || 'O campo espaço é obrigatório'],
                fornecedor: [(v) => !!v || 'O campo fornecedor é obrigatório'],
                bandeira: [(v) => !!v || 'O campo bandeira é obrigatório'],
                modelo: [
                    (v) =>
                        !!v ||
                        this.value.fnLeitura ||
                        //this.value.fnReplicar ||
                        'O campo modelo check-out é obrigatório',
                ],
                testeira: [
                    (v) =>
                        (this.value.metodo_criacao == 'MD' && !!v) ||
                        'O campo espaço é obrigatório',
                ],
                marca: [
                    (v) =>
                        (this.value.metodo_criacao == 'MD' && !!v) ||
                        'O campo espaço é obrigatório',
                ],
                /// investimento deve ser maior que 0
                investimento_total: [
                    (v) => !!v || 'O campo valor de investimento é obrigatório',
                    (v) => {
                        if (!v)
                            return 'O campo valor de investimento é obrigatório'
                        const valor = v
                            .replace('R$ ', '')
                            .replaceAll('.', '')
                            .replace(',', '.')
                        return (
                            Number(valor) > 0 ||
                            'O campo valor de investimento deve ser maior que 0'
                        )
                    },
                ],
                forma_pagamento: [
                    (v) => !!v || 'O campo forma de pagamento é obrigatório',
                ],
                parcelas: [
                    (v) => !!v || 'O campo número de parcelas é obrigatório',
                ],
            },
            semAcesso: false,
            loadingMarcas: false,
            listaMarcas: [],
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
        }
    },
    async created() {
        var lista = await this.listaCampos
        this.lista.bandeira = lista.bandeira
        this.lista.espaco = lista.espaco


    },
    computed: {
        // marcoTodosEspacos() {
        //     return (
        //         this.ids_espaco_dermo.length === this.lista.moveisDermo.length
        //     )
        // },
        // marcoAlgunsEspacos() {
        //     return this.ids_espaco_dermo.length > 0 && !this.marcoTodosEspacos
        // },
        // icon() {
        //     if (this.marcoTodosEspacos) return 'mdi-close-box'
        //     if (this.marcoAlgunsEspacos) return 'mdi-checkbox-blank-outline'
        //     return 'mdi-checkbox-blank-outline'
        // },
        mesAtual() {
            return this.$moment().format('YYYY-MM')
        },
    },
    watch: {
        async buscaProdutoFornecedor(val) {
            if (!val) {
                this.id_produtoBusca = null
                return
            }

            if (val.length < 4) return

            if (this.isLoadingProduto) return

            this.isLoadingProduto = true

            var data = {
                produto: val,
                negociacao_comercial: 'SIM',
            }

            var response = await this.$http().post('/acordo/buscaProdutos', {
                data: data,
            })

            this.lista.produto = response.data

            this.isLoadingProduto = false
        },
        id_produtoBusca(val) {
            var produto = this.lista.produto.find((e) => e.value == val)

            this.value.id_fornecedor = produto ? produto.id_fornecedor : null
            this.value.fornecedor = produto ? produto.fornecedor : null
        },
    },
    methods: {
        changed() {
            // console.log('changed', this.ids_espaco_dermo)
            // if (!this.value.moveisDermo) this.value.moveisDermo = []
            // for (let espaco of this.ids_espaco_dermo) {
            //     var espacoChecado = _.find(this.value.moveisDermo, {
            //         id_espaco: espaco,
            //     })
            //     console.log(espaco, espacoChecado, this.lista.moveisDermo)
            //     if (espacoChecado == undefined) {
            //         console.log(
            //             _.find(this.lista.moveisDermo, { id_espaco: espaco })
            //         )
            //         this.value.moveisDermo.push(
            //             _.find(this.lista.moveisDermo, { id_espaco: espaco })
            //         )
            //     }
            // }
            // this.value.moveisDermo = this.value.moveisDermo.filter((item) => {
            //     return this.ids_espaco_dermo.includes(item.id_espaco)
            // })
            // if (this.value.moveisDermo.length > 0) {
            //     this.setInformacoesAdicionais(
            //         this.value.moveisDermo[0].id_espaco
            //     )
            // }
            // console.log(this.value.moveisDermo)
        },
        toggle() {
            // this.$nextTick(() => {
            //     var text = ''
            //     if (this.marcoTodosEspacos) {
            //         this.ids_espaco_dermo = []
            //         text = 'Nenhum espaço'
            //     } else {
            //         this.ids_espaco_dermo = this.lista.moveisDermo.map(
            //             (item) => {
            //                 return item.value
            //             }
            //         )
            //         text = 'Todos espaços'
            //     }
            // })
        },

        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso
        },
        limpaCadastro() {
            this.resetValidation()
            this.id_produtoBusca = null
            this.buscaProdutoFornecedor = null
            this.fornecedor = null
            this.lista.produto = []
            this.acordo = {
                mesInicial: null,
                mesFinal: null,
                id_fornecedor: null,
                fornecedor: null,
                bandeira: null,
                obs_departamento: null,
                obs_contrato: null,
                comentario: null,
                fnEditar: false,
            }
        },

        // setInformacoesAdicionais(event) {
        //     console.log('setInformacoesAdicionais', espacoSeleciondo[0])
        // },

        limpaProdutos() {
            this.$emit('limpaProdutos')
        },

        validate() {
            return this.$refs.form.validate()
        },
        negociacaoChange() {
            this.limpaProdutos()
            this.limpaCadastro()
        },
        produtoChange() {
            this.limpaProdutos()
        },
        resetValidation() {
            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }
        },
        periodoInicialChange(valor) {
            if (this.value.mesFinal < valor) {
                this.value.mesFinal = valor
            }
        },
        periodoFinalChange(valor) {
            if (this.value.mesInicial > valor) {
                this.value.mesInicial = valor
            }
        },
    },
}
</script>
