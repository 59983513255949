<template>
  <v-container fluid>
    <v-card class="px-5 py-3 br-15 w-100" elevation="0">
      <filtro
          ref="filtro"
          @filtrar="filtrar"
          @limpar="limpar"
        ></filtro>
      </v-card>
      <Indicadores :totais="totais" />
      <graficos :totais="totais" />
      <v-card class="px-5 py-5 br-15 w-100 mt-5" elevation="0">
        <v-toolbar
        flat
      >

        <v-toolbar-title>Oportunidades</v-toolbar-title>

        <v-spacer></v-spacer>

        

        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            align-with-title
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab>
              Ocupação semanal
            </v-tab>
            <v-tab>
              Ocupação diária
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-divider class="mt-2 mb-3"></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-row class="mb-2">
              <v-col cols="4" class="d-flex pt-2">
                <InfoBox expands>
                  <p>
                    <span style="color: var(--v-primary-base); font-weight: 500">
                      Oportunidades:
                    </span>
                    Com ênfase em mostrar os dados relacionados as oportunidades de
                    maneira detalhada, a planilha pode ser visualizada através dos
                    meses selecionados ou dos valores totais desse período. Para
                    escolher qual modo deseja visualizar, selecione a opção desejada
                    no canto superior esquerdo da tela.
                  </p>
                  <p>
                    Na aba "oportunidades (%)" as cores nos círculos são diretamente
                    correspondentes a porcentagem dos valores. sendo o verde utilizado
                    para representar os valores de
                    <span style="color: var(--v-primary-base); font-weight: 500">
                      0% a 50%</span
                    >, o amarelo para valores de
                    <span style="color: #ff9f1c; font-weight: 500"> 51% a 80%</span>
                    e o vermelho para valores de
                    <span style="color: var(--v-red-base); font-weight: 500">
                      81% a 100%</span
                    >.
                  </p>
                </InfoBox>
                <v-switch
                  v-model="porcentagem"
                  hide-details
                  class="mt-0 ml-3"
                  label="Valores em porcentagem"
                ></v-switch>
              </v-col>
              <v-col cols="4" class="pt-2">
                <h2 class="text-center" style="font-weight: normal">Oportunidades</h2>
              </v-col>
              <v-col cols="4" class="d-flex justify-end pt-2">
                <v-menu offset-y min-width="180">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      color="#f2f2f2"
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="px-1"
                      style="min-width: unset"
                    >
                      <v-icon color="#CCC">mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <v-list-item link @click="exportar()">
                      <v-list-item-icon class="pl-2 mr-0">
                        <v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar CSV</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-data-table
              class="elevation-0 tabela"
              :headers="compHeaders.headers"
              :items="compData"
              :items-per-page="5"
              :footer-props="tableConfig"
              :header-props="{ sortIcon: 'mdi-chevron-down' }"
              item-key="id_ativacao"
              multi-sort
            >
              <template v-slot:header="{}">
                <thead>
                  <tr>
                    <th
                      v-for="(h, i) in compHeaders.headersMeses"
                      :key="i"
                      :colspan="h.colspan"
                      :class="h.class"
                    >
                      {{ h.text }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      v-for="(h, i) in compHeaders.headersSemanas"
                      :key="i"
                      :colspan="h.colspan"
                      :class="h.class"
                    >
                      {{ h.text }}
                    </th>
                  </tr>
                </thead>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-row class="mb-2">
              <v-col cols="4" class="d-flex pt-2">
                <InfoBox expands>
                  <p>
                    <span style="color: var(--v-primary-base); font-weight: 500">
                      Oportunidades:
                    </span>
                    Com ênfase em mostrar os dados relacionados as oportunidades de
                    maneira detalhada, a planilha pode ser visualizada através dos
                    meses selecionados ou dos valores totais desse período. Para
                    escolher qual modo deseja visualizar, selecione a opção desejada
                    no canto superior esquerdo da tela.
                  </p>
                  <p>
                    Na aba "oportunidades (%)" as cores nos círculos são diretamente
                    correspondentes a porcentagem dos valores. sendo o verde utilizado
                    para representar os valores de
                    <span style="color: var(--v-primary-base); font-weight: 500">
                      0% a 50%</span
                    >, o amarelo para valores de
                    <span style="color: #ff9f1c; font-weight: 500"> 51% a 80%</span>
                    e o vermelho para valores de
                    <span style="color: var(--v-red-base); font-weight: 500">
                      81% a 100%</span
                    >.
                  </p>
                </InfoBox>
                <v-switch
                  v-model="porcentagem"
                  hide-details
                  class="mt-0 ml-3"
                  label="Valores em porcentagem"
                ></v-switch>
              </v-col>
              <v-col cols="4" class="pt-2">
                <h2 class="text-center" style="font-weight: normal">Oportunidades</h2>
              </v-col>
              <v-col cols="4" class="d-flex justify-end pt-2">
                <v-menu offset-y min-width="180">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      color="#f2f2f2"
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="px-1"
                      style="min-width: unset"
                    >
                      <v-icon color="#CCC">mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <!-- <v-list-item link @click="exportar()">
                      <v-list-item-icon class="pl-2 mr-0">
                        <v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar CSV</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
                    <download-csv
                                        :data   = "compExportDiario"
                                        :separator-excel="true"
                                        name="diario.csv"
                                        encoding="windows-1252"
                                        >
                                        <v-icon color="grey lighten-1"
                                        >mdi-microsoft-excel</v-icon>
                                        Exportar CSV
                                    </download-csv>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-data-table
              class="elevation-0 tabela tabelaDiaria"
              :headers="compHeadersDiarios.headers"
              :items="compData"
              :items-per-page="5"
              :footer-props="tableConfigDiario"
              :header-props="{ sortIcon: 'mdi-chevron-down' }"
              item-key="id_ativacao"
              multi-sort
            >
              <template v-slot:header="{}">
                <thead>
                  <tr>
                    <th
                      v-for="(h, i) in compHeadersDiarios.headersMeses"
                      :key="i"
                      :colspan="h.colspan"
                      :class="h.class"
                    >
                      {{ h.text }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      v-for="(h, i) in compHeadersDiarios.headersSemanas"
                      :key="i"
                      :colspan="h.colspan"
                      :class="h.class"
                    >
                      {{ h.text }}
                    </th>
                  </tr>
                </thead>
                
              </template>
              <template v-slot:body="{items}">
                <tbody>
                  <!-- <tr
                  >
                    <td  
                    v-for="(h, i) in compHeadersDiarios.headers"
                      :key="i"
                      :colspan="h.colspan"
                      :class="h.class"
                    >{{ h.text }}</td>
                  </tr> -->
                  <tr
                    v-for="item in items"
                    :key="item.name"
                  >
                    <td  v-for="header in compHeadersDiarios.headers" :key="header.value" :class="item.class" v-html="header.value[0] == 'd' ? printCellDiaria(header.value,item[header.value]) : item[header.value]"></td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      
      
    </v-card>
    <v-overlay :value="carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
  
  <script>
import Filtro from "./Filtro.vue";
import Indicadores from "./Indicadores.vue";
import Graficos from "./Graficos.vue";
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  components: { Filtro, Indicadores, Graficos },
  data() {
    return {
      tab: null,
      items: [
        'ocupação semanal', 'ocupação diária',
      ],
      text: 'Lorem ipsum dolor sit amet, ',      
      // filtro: {
      //   mesInicial: this.$moment().format("YYYY-MM"),
      //   mesFinal: this.$moment().format("YYYY-MM"),
      // },
      colunasAgrupadas: [],
      oportunidade: [],
      mesesResposta: [],
      porcentagem: false,
      carregando: false,
      totais: {},
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      tableConfig: {
        itemsPerPageOptions: [5, 10, 20, 50, 100, -1],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}",
      },
      tableConfigDiario: {
        itemsPerPageOptions: [5, 10, 20, 50, 100, -1],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}",
      },
    };
  },
  computed: {
    ...mapFields('planejamento_ecommerce_gerencial',[
        'filtro.mesInicial',
        'filtro.mesFinal',
        'filtro.tipoPeriodo',
        'filtro.id_bandeira',
        'filtro.id_planejamento',
        'filtro.semana',
        'filtro.id_ativacao',
        'filtro.device',
        'filtro.id_termo',
        'filtro.id_area',
        'filtro.id_fornecedor',
        'filtro.id_categoria',
        'filtro.agrupar',
        'filtro.plano',
        'filtro.id_status_planejamento',
        'filtro.id_macrofornecedor'
    ]),
    compSemanas() {
      if (this.oportunidade.length == 0) return [];
      const semanas = Object.keys(this.oportunidade[0])
        .filter((key) => key.startsWith("inventario"))
        .map((key) => key.split("inventario_")[1]);
        // console.log('semanas',semanas)
      return semanas;
    },
    compMeses() {
      if (this.oportunidade.length == 0) return [];
      const meses = new Set();
      this.compSemanas.forEach((semana) => {
        const [s, ...mes] = semana.split("_");
        meses.add(mes.join("_"));
      });
      return meses;
    },
    compExportDiario () {
      console.log('passou aqui')
      // let totalOportunidade = 0;
      if (this.oportunidade.length == 0) return [];
      const semanas = this.compSemanas;
      console.log(semanas)
      const oportunidade = JSON.parse(JSON.stringify(this.compData));
      let keysNames = _.keys(oportunidade[0])
      let colunasD = keysNames.filter(item => item[0] == 'd') 
      let titulos = keysNames.filter(item => {
        return ((item[0] != 'd') && (!item.startsWith('oportunidade_')) && (!item.startsWith('comprometido_')) && (!item.startsWith('inventario_')))
      }) 
      const colunasDChunks = _.chunk(colunasD,7);
      return oportunidade.map((oportunidade) => {
        let object = {}
        let somaInventario = 0
        let somaOcupacoes = 0
        for (var itemKey of titulos) {
          object[itemKey] = oportunidade[itemKey]  
          if (['comprometido','oportunidade'].includes(itemKey)) {
            object[itemKey] = (object[itemKey] + '0').replace('.',',')
          }
        }
        let pos = 0
        for (var semana of colunasDChunks) {
          if (semanas.length > pos) {
            somaInventario += Number(oportunidade['inventario_' + semanas[pos]] || 0) * 7
  
            object['inventario_' + semanas[pos]] = oportunidade['inventario_' + semanas[pos]]  
            for (var itemKeyB of semana) {
              console.log('itemKeyB',itemKeyB)
              somaOcupacoes += Number(oportunidade[itemKeyB] || 0 )
              object['dia_'+itemKeyB[7] + itemKeyB[8] + '_' + itemKeyB[5] + itemKeyB[6]] = oportunidade[itemKeyB]  
            }
          }
          pos++
        }
        object['comprometido'] = somaOcupacoes
        object['oportunidade'] = somaInventario - somaOcupacoes
        return object
      })
    },
    compData() {
      if (this.oportunidade.length == 0) return [];

      const semanas = this.compSemanas;
      const oportunidade = JSON.parse(JSON.stringify(this.oportunidade));

      return oportunidade.map((oportunidade) => {
        let totalComprometido = 0;
        let totalOportunidade = 0;
        let totalComprometidoAbsoluto = 0;
        let totalOportunidadeAbsoluto = 0;
        let totalInventarioAbsoluto = 0;
        let keysNames = _.keys(this.oportunidade[0])
        let colunasD = keysNames.filter(item => item[0] == 'd') 
        const colunasDChunks = _.chunk(colunasD,7);
        if (oportunidade.tipo == 'Inventario') {
          console.log('inventario')  
          semanas.forEach((semana,i) => {
            const comprometido = Number(
              oportunidade[`comprometido_${semana}`] || 0
            );
            const comprometidoAbsoluto = Number(
              oportunidade[`comprometido_absoluto_${semana}`] || 0
            );
            const inventario = Number(oportunidade[`inventario_${semana}`] || 0);
            const livre = inventario - comprometido;
            const livreAbsoluto = inventario - comprometidoAbsoluto;
            totalComprometido += comprometido;
            // totalComprometidoAbsoluto += comprometidoAbsoluto;
            totalOportunidade += livre;
            totalOportunidadeAbsoluto += livreAbsoluto;
            totalInventarioAbsoluto += (inventario * 7)
            
            oportunidade[`oportunidade_${semana}`] = livre.toFixed(2);
            for (var coluna of colunasDChunks[i]) {
                  // oportunidade[coluna] = ((oportunidade[coluna] / oportunidade[`inventario_${semana}`]) * 100).toFixed(2) + '%'
                  totalComprometidoAbsoluto = totalComprometidoAbsoluto + Number(oportunidade[coluna] || 0)
                  oportunidade[`comprometido_absoluto_${semana}`] = oportunidade[`comprometido_absoluto_${semana}`] + oportunidade[coluna];
                  oportunidade[`oportunidade_absoluto_${semana}`] = oportunidade[`oportunidade_absoluto_${semana}`] + oportunidade[coluna];
            }
            oportunidade[`comprometido_${semana}`] = comprometido.toFixed(2);

            if (this.porcentagem) {
              const oportunidadePercent = (livre / inventario) * 100;
              const comprometidoPercent = (comprometido / inventario) * 100;
              oportunidade[`oportunidade_${semana}`] =
                oportunidadePercent.toFixed(2) + " %";
              oportunidade[`comprometido_${semana}`] =
                comprometidoPercent.toFixed(2) + " %";
             
              for (coluna of colunasDChunks[i]) {
                  oportunidade[coluna] = ((oportunidade[coluna] / oportunidade[`inventario_${semana}`]) * 100).toFixed(2) + '%'
              }

            }
          });
          if (this.porcentagem) {
            const comprometidoPercent =
              (totalComprometido / totalOportunidade) * 100;
            const oportunidadePercent = 100 - comprometidoPercent;
            const comprometidoAbsolutoPercent =
              (totalComprometidoAbsoluto / (totalInventarioAbsoluto )) * 100;
            const oportunidadeAbsolutoPercent = 100 - comprometidoAbsolutoPercent;

            oportunidade[`comprometido`] = comprometidoPercent.toFixed(2) + " %";
            oportunidade[`oportunidade`] = oportunidadePercent.toFixed(2) + " %";
            oportunidade[`comprometido_absoluto`] = comprometidoAbsolutoPercent.toFixed(2) + " %";
            oportunidade[`oportunidade_absoluto`] = oportunidadeAbsolutoPercent.toFixed(2) + " %";

          } else {
            oportunidade[`comprometido`] = totalComprometido.toFixed(2)
            oportunidade[`comprometido_absoluto`] = totalComprometidoAbsoluto
            oportunidade[`oportunidade`] = totalOportunidade.toFixed(2);
            oportunidade[`oportunidade_absoluto`] = totalInventarioAbsoluto - totalComprometidoAbsoluto;
            console.log('oportunidade_absoluto',totalInventarioAbsoluto , totalComprometidoAbsoluto)
          }

        } else {
          semanas.forEach((semana) => {
            const comprometido = Number(
              oportunidade[`comprometido_${semana}`] || 0
            );
            const inventario = Number(oportunidade[`inventario_${semana}`] || 0);
            const livre = '-';
            totalComprometido += comprometido;
            totalOportunidade = '-';

            oportunidade[`oportunidade_${semana}`] = livre;
            oportunidade[`comprometido_${semana}`] = comprometido;

            if (this.porcentagem) {
              const oportunidadePercent = (livre / inventario) * 100;
              const comprometidoPercent = (comprometido / inventario) * 100;
              oportunidade[`oportunidade_${semana}`] =
                " - ";
              oportunidade[`comprometido_${semana}`] =
                " - ";
            }
          });
          if (this.porcentagem) {
            // const comprometidoPercent =
            //   (totalComprometido / totalOportunidade) * 100;
            // const oportunidadePercent = 100 - comprometidoPercent;
            oportunidade[`comprometido`] = " - ";
            oportunidade[`oportunidade`] = " - ";
          } else {
            oportunidade[`comprometido`] = totalComprometido.toFixed(2);
            oportunidade[`oportunidade`] = totalOportunidade == '-' ? 0 : totalOportunidade.toFixed(2);
          }
        }
        return oportunidade;
      });
    },
    compHeaders() {
      const headers = [];
      const headersSemanas = [];
      const headersMeses = [];
      const countColunas = this.colunasAgrupadas?.length || 0;
      if (countColunas > 0) {
        headersSemanas.push({
          colspan: countColunas,
          class: "header-light",
        });
      }

      function ajusteMacro(header) {
        if (header == 'macrofornecedor') return 'Macro Fornecedor'
        return header
      }

      console.log('colunasAgrupadas',this.colunasAgrupadas)

      this.colunasAgrupadas?.forEach((coluna) => {
        headers.push({
          text: ajusteMacro(coluna),
          value: coluna,
          align: "center",
          class: "th-separador header-light capitalize",
          sortable: true,
        });
      });

      headersSemanas.push({
        text: `Total`,
        value: `Total`,
        class: `text-center  header `,
        align: "center",
        width: "100px",
        colspan: 2,
        sortable: true,
      });

      headers.push(
        {
          text: "Ocupação",
          value: "comprometido",
          class: "  text-center th-separador",
          align: "center",
          sortable: true,
        },
        {
          text: "Disponível",
          value: "oportunidade",
          class: "text-center",
          align: "center",
          sortable: true,
        }
      );
      let dark = false;
      this.compSemanas.forEach((coluna) => {
        dark = !dark;
        const [semana, ...mes] = coluna.split("_");
        const mesAtual = this.$moment(mes.join("-"), "MM-YYYY");

        headersSemanas.push({
          text: `Semana ${semana} - ${mesAtual
            .format("MMM.  YYYY")
            .capitalize()}`,
          value: `semana_${coluna}`,
          class: `text-center  header${dark ? "-light" : ""}  `,
          align: "center",
          width: "100px",
          colspan: 2,
          sortable: true,
        });

        headers.push(
          {
            text: `Ocupação`,
            value: `comprometido_${coluna}`,
            class: `text-center  header${
              dark ? "-light" : ""
            }   th-separador`,
            align: "center",
            sortable: true,
          },
          {
            text: `Disponível`,
            value: `oportunidade_${coluna}`,
            class: `text-center header${dark ? "-light" : ""}   `,
            align: "center",
            sortable: true,
          }
        );
      });

      return {
        headers,
        headersSemanas,
        headersMeses,
      };
    },
    compHeadersDiarios() {
      let dark = false;

      const headers = [];
      const headersSemanas = [];
      const headersMeses = [];
      const countColunas = this.colunasAgrupadas?.length || 0;
      if (countColunas > 0) {
        headersSemanas.push({
          colspan: countColunas,
          class: "header-light",
        });
      }

      function ajusteMacro(header) {
        if (header == 'macrofornecedor') return 'Macro Fornecedor'
        return header
      }

      this.colunasAgrupadas?.forEach((coluna) => {
        headers.push({
          text: ajusteMacro(coluna),
          value: coluna,
          align: "center",
          class: "th-separador header-light capitalize",
          sortable: true,
        });
      });

      headersSemanas.push({
        text: `Total`,
        value: `Total`,
        class: `text-center  header `,
        align: "center",
        width: "100px",
        colspan: 2,
        sortable: true,
      });

      headers.push(
        // {
        //   text: "Inventario",
        //   value: "inventario",
        //   class: "  text-center th-separador",
        //   align: "center",
        //   sortable: true,
        // },
        {
          text: "Ocupação",
          value: "comprometido_absoluto",
          class: `text-center  header${
              dark ? "-light" : ""
            }   th-separador`,
            align: "center",
          sortable: true,
        },
        {
          text: "Disponível",
          value: "oportunidade_absoluto",
          class: `text-center header${dark ? "-light" : ""}   th-separador `,
            align: "center",
          sortable: true,
        }
      );
      let pos = 0
      // console.log(this.compSemanas)
      this.compSemanas.forEach((coluna,index) => {
        // console.log(coluna)
        dark = !dark;
        const [semana, ...mes] = coluna.split("_");
        const mesAtual = this.$moment(mes.join("-"), "MM-YYYY");

        headersSemanas.push({
          text: `Semana ${semana} - ${mesAtual
            .format("MMM.  YYYY")
            .capitalize()}`,
          value: `semana_${coluna}`,
          class: `text-center  header${dark ? "-light" : ""}  `,
          align: "center",
          width: "100px",
          colspan: 8,
          // this.porcentagem ? 7 : 8,
          sortable: true,
        });

        console.log('this.oportunidade',this.oportunidade)

        if (this.oportunidade !== undefined && this.oportunidade && this.oportunidade.length > 0) {
          let keysNames = _.keys( this.oportunidade[0])
          let colunasD = keysNames.filter(item => item[0] == 'd') 
          let colunasI = keysNames.filter(item => (item[0] + item[1] + item[2]) == 'inv') 
          const colunasDChunks = _.chunk(colunasD,7);

          
          
          console.log('keysNames',colunasI[pos])
          // for (const colunaD of colunasDChunks[index ]) {
            headers.push(
              {
                text: `Inv.`,
                value: `${colunasI[pos]}`,
                class: `text-center  header${
                  dark ? "-light" : ""
                }   th-separador`,
                // align: `${this.porcentagem ? ' d-none' : 'center'}`,
                sortable: false,
              },
            )  
            pos = pos + 1
            // console.log(index, colunasDChunks[index ])
            for (const colunaD of colunasDChunks[index ]) {
              headers.push(
                {
                  text: `${colunaD[7] + colunaD[8]}`,
                  value: `${colunaD}`,
                  class: `text-center  header${
                    dark ? "-light" : ""
                  }   th-separador`,
                  align: "center",
                  sortable: false,
                },
              )  
          }
        } 

        // headers.push(
          // {
          //   text: `Ocupação`,
          //   value: `comprometido_${coluna}`,
          //   class: `text-center  header${
          //     dark ? "-light" : ""
          //   }   th-separador`,
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   text: `Disponível`,
          //   value: `oportunidade_${coluna}`,
          //   class: `text-center header${dark ? "-light" : ""}   `,
          //   align: "center",
          //   sortable: true,
          // }

        // );
      });

      return {
        headers,
        headersSemanas,
        headersMeses,
      };
    },
  },
  mounted() {
    this.filtrar();
    const tables = document.getElementsByClassName("tabelaDiaria");
    // this.addTableDataBar(tables[0])
  },
  methods: {
    ...mapActions('planejamento_ecommerce_gerencial',['limparFiltro','saveCache']),
    addTableDataBar(tableElm, opts = {}) {
      const options = {
        color: "rgba(179, 255, 201,1)" /* CSS color */,
        dataAttr: null /* look for data-{attribute} for value on table cell */,
        ...opts
      };
      let cols = tableElm.dataset.barcol.split(",");
      cols = cols.map((v) => parseInt(v, 10));

      cols.forEach((col) => {
        // get elms
        const dataElms = tableElm.querySelectorAll(`tbody tr td:nth-child(${col})`);
        // get values
        const values = [];
        let max = 0;
        dataElms.forEach(function (elm, i) {
          const row = {
            elm: elm,
            value: parseFloat(
              options.dataAttr != null
                ? elm.dataset[options.dataAttr]
                : elm.innerHTML
            ),
            percent: null
          };
          max = Math.max(max, row.value);
          values.push(row);
        });
        // Calc percent
        values.forEach(function (row) {
          row.percent = (row.value / max) * 100;
        });
        // add background bar
        values.forEach(function (row) {
          // set background bar
          const color =
            typeof options.color === "function"
              ? options.color(row.percent / 100)
              : options.color;
          if (row.percent !== 0) {
            row.elm.style.background = `linear-gradient(90deg, ${color} 0%, ${color} ${row.percent}%, rgba(0,0,0,0) ${row.percent}%)`;
          }
        });
      });
    },
    createBackgroundString(field,value) {
      const options = {
        color: "rgba(255, 192, 122,1)" /* CSS color */,
        dataAttr: null /* look for data-{attribute} for value on table cell */,
        
      };
      return `linear-gradient(0deg, ${options.color} 0%, ${options.color} ${70}%, rgba(30, 152, 138,1) ${70}%)`;
    },
    printCellDiaria(field,value) {
      //style=" background:linear-gradient(360deg, rgba(30, 152, 138,1) ${100 - parseInt(value,10)}%, rgba(255, 192, 122,1) ${parseInt(value,10)}%)"
      if (this.porcentagem) {
        return `
          <div class="pilula" >
            <div class=" ${parseInt(value,10) > 0 ? 'pilulaDisponivel' : 'pilulaFree'}" style="width: ${parseInt(value,10)}%;">
            ${value}
            </div>
          </div>
        `
      } else {
        return value
      }
    },
    limpar() {
      this.limparFiltro();
      this.filtrar();
    },
    async exportar() {
      if (!this.$refs.filtro.validate()) return;
      this.carregando = true;
      // const filtro = this.$refs.filtro.filtro;

      const data = {
        mesInicial: this.mesInicial,
        mesFinal: this.mesFinal,
        tipoPeriodo: this.tipoPeriodo,
        id_bandeira: this.id_bandeira,
        semana: this.semana,
        id_ativacao: this.id_ativacao,
        device: this.device,
        id_area: this.id_area,
        id_fornecedor: this.id_fornecedor,
        id_categoria: this.id_categoria,
        agrupar: this.agrupar,
        plano: this.plano,
        id_status_planejamento: this.id_status_planejamento ? this.id_status_planejamento : [],
      };
      data.mesInicial = this.mesInicial
      ? this.$moment(this.mesInicial).format("YYYY-MM")
      : this.$moment().format("YYYY-MM");
      data.mesFinal = this.mesFinal
      ? this.$moment(this.mesFinal).format("YYYY-MM")
      : this.$moment().format("YYYY-MM");
      const response = await this.$http().post(
        "/planejamentoEcommerce/gerencial/exportarOportunidades",
        { data: data }
      );
      if (response.status == 200 && response.data.url) {
        window.location = this.$http("baseURL") + response.data.url;
      } else {
 //       console.log(response);
      }
      this.carregando = false;
    },
    async filtrar() {
      if (!this.$refs.filtro.validate()) return;
      this.carregando = true;
      // const filtro = this.$refs.filtro.filtro;
      const data = {
        mesInicial: this.mesInicial,
        mesFinal: this.mesFinal,
        tipoPeriodo: this.tipoPeriodo,
        id_bandeira: this.id_bandeira,
        semana: this.semana,
        id_ativacao: this.id_ativacao,
        device: this.device,
        id_termo: this.id_termo,
        id_planejamento: this.id_planejamento,
        id_macrofornecedor: this.id_macrofornecedor,
        id_area: this.id_area,
        id_fornecedor: this.id_fornecedor,
        id_categoria: this.id_categoria,
        agrupar: this.agrupar,
        plano: this.plano,
        id_status_planejamento: this.id_status_planejamento ? this.id_status_planejamento : [],
      };
      data.mesInicial = this.mesInicial
        ? this.$moment(this.mesInicial).format("YYYY-MM")
        : this.$moment().format("YYYY-MM");
      data.mesFinal = this.mesFinal
        ? this.$moment(this.mesFinal).format("YYYY-MM")
        : this.$moment().format("YYYY-MM");

      const response = await this.$http().post(
        "/planejamentoEcommerce/gerencial/oportunidades",
        {
          data: data,
        }
      );
      const totais = await this.$http().post(
        "/planejamentoEcommerce/gerencial/totais",
        {
          data: data,
        }
      );

      console.log(896,totais)

      if (totais.status == 200 && totais.data?.data) {
        this.totais = totais.data.data;
      }

      if (response.status == 200 && response.data?.data) {
        const keys = _.keys(response.data.data[0])
        console.log(keys)
        let oportunidades = []
        if (keys.includes('device')) {
          oportunidades = response.data.data.filter(item => {
            if (!item.device) return false
            return (item.device !== null)
          });
        } else {
          oportunidades = response.data.data
        }
        console.log(oportunidades)
        // this.processaResposta(oportunidades);
        this.oportunidade = oportunidades;
        // this.oportunidade = oportunidade;
        // this.mesesResposta
      }
      this.colunasAgrupadas = data.agrupar;
      // console.log(data)
      this.saveCache()
      this.carregando = false;
    },
  },
};
</script>
  
<style>
.pilula {
  background-color: #1e988a;
  border: none;
  color: black;
  /* padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 12px;
}
.pilulaDisponivel {
  background-color: #ffc07a; 
  color: white; 
  padding: 6px 10px; 
  text-align: right; 
  font-size: 12px; 
  width: 0px;
  border-radius: 12px; 
  transition: width 500ms ease-in-out;
}
.pilulaFree {
  background-color: #1e988a;
  color: white; 
  padding: 6px 10px; 
  text-align: right; 
  font-size: 12px; 
  width: 0px;
  border-radius: 12px; 
}
.highcharts-background {
  width: 100%;
}
th {
  font-weight: normal;
  font-size: 0.9rem !important;
}

.stackLabels {
  font-family: "TT Norms", "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  color: #808080;
  margin-bottom: 5px;
}
.stackLabels .oportunidade {
  text-align: center;

  color: var(--v-primary-base);
}
.stackLabels .comprometido {
  text-align: center;
  color: var(--v-yellow-base);
}
.grafico {
  position: relative;
}
.grafico-frame {
  top: 45px;
  width: 100%;
  height: calc(100% - 45px);
  border: 2px solid #f2f2f2;
  border-radius: 15px 15px 0 0;
  position: absolute;
  overflow: hidden;
}
.grafico-frame::before {
  content: "";
  width: 100%;
  height: 58px;
  background: #f2f2f2;
  bottom: 0;
  position: absolute;
  border-top: 1px solid #ddd;
}

.tabela .v-data-table__wrapper {
  border-radius: 10px 10px 0 0;
  border: 1px solid #eaeaea !important;
}

tr.total td {
  text-align: center;
  background-color: #f2f2f2;
}

tr.total td:nth-child(odd) {
  background-color: #f8f8f8;
}
.capitalize {
  text-transform: capitalize;
}
.tabela td {
  font-size: 0.8rem !important;
  
}
.header-dark {
  background: var(--v-primary-darken1);
}
.header {
  background: var(--v-primary-base);
}
.header-light {
  background: var(--v-primary-lighten1);
}
.header-light-2 {
  background: var(--v-primary-lighten2);
}
.circulo {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.circulo.verde {
  background: var(--v-primary-base);
}
.circulo.amarelo {
  background: #ff9f1c;
}
.circulo.vermelho {
  background: var(--v-red-base);
}
.expand-buttons .v-input--radio-group__input {
  font-size: 0.8rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.expand-buttons .v-label {
  font-size: 0.8rem;
  color: #fff;
}
.expand-buttons .v-input--selection-controls__input {
  transform: scale(70%);
  margin: 0;
}
.expand-buttons .v-radio {
  margin-right: 5px !important;
}
.center-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.td-taxa-ocupacao {
  display: inline-flex;

  justify-content: center;
  align-items: center;
  height: 100%;
}
.legenda-oportunidades {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 6px;
  padding: 8px 0;
  color: #808080;
  font-size: 0.8rem;
  font-weight: normal;
}
.legenda-oportunidades .comprometido {
  background: var(--v-yellow-base);
  width: 25px;
  height: 20px;
  margin: 0 12px;
  border-radius: 4px;
}
.legenda-oportunidades .oportunidade {
  background: var(--v-primary-base);
  width: 25px;
  height: 20px;
  margin: 0 12px;
  border-radius: 4px;
}
</style>
