<template>
    <v-dialog
        v-model="dialogForm"
        scrollable
        persistent
        content-class="dialog-pdv br-15"
    >
        <v-card>
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title>
                    {{ formTitulo }} - Etapa ({{ passo }} de 5)
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    color="#fff"
                    @click="dialogForm = false"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
  
            <!-- <v-card-text class="pa-0"> -->
            <v-stepper v-model="passo" non-linear class="elevation-0 card-body">
                <v-stepper-header class="px-2">
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa1"
                        step="1"
                        >Cadastro de Acordo</v-stepper-step
                    >
                    <v-divider class="mx-2"></v-divider>
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa2"
                        step="2"
                        >Seleção de Filiais</v-stepper-step
                    >
                    <v-divider class="mx-2"></v-divider>
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa3"
                        step="3"
                        >Desconto</v-stepper-step
                    >
                    <v-divider
                        class="mx-2"
                        v-if="!acordo.preAcordo"
                    ></v-divider>
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa4"
                        v-if="!acordo.preAcordo"
                        step="4"
                        >Seleção de Produtos</v-stepper-step
                    >
                    <v-divider
                        class="mx-2"
                        v-if="!acordo.preAcordo"
                    ></v-divider>
                    <v-stepper-step
                        editable
                        error-icon="mdi-alert-circle"
                        :rules="regra.etapa5"
                        v-if="!acordo.preAcordo"
                        step="5"
                        >Efetivação do acordo</v-stepper-step
                    >
                </v-stepper-header>
                <div class="etapas-conteudo">
                    <v-stepper-items class="min-h-100">
                        <v-stepper-content class="min-h-100" step="1">
                            <cadastro
                                ref="cadastro"
                                :usuarioTipo="usuarioTipo"
                                v-model="acordo"
                                @limpaFiliais="$refs.filial.limpaSelecionadas()"
                                @limpaProdutos="$refs.produto.limpaProdutos()"
                                @limpaDescontos="
                                    $refs.desconto.limpaDescontos()
                                "
                            ></cadastro>
                        </v-stepper-content>
  
                        <v-stepper-content class="min-h-100" step="2">
                            <filial
                                ref="filial"
                                @limpaDescontos="
                                    $refs.desconto.limpaDescontos()
                                "
                                v-model="acordo"
                            ></filial>
                        </v-stepper-content>
  
                        <v-stepper-content class="min-h-100" step="3">
                            <desconto
                                ref="desconto"
                                v-model="acordo"
                            ></desconto>
                        </v-stepper-content>
  
                        <v-stepper-content class="min-h-100" step="4">
                            <produto ref="produto" v-model="acordo"></produto>
                        </v-stepper-content>
  
                        <v-stepper-content class="min-h-100" step="5">
                            <planograma
                                ref="planograma"
                                v-model="acordo"
                            ></planograma>
                        </v-stepper-content>
                    </v-stepper-items>
                </div>
  
                <div class="d-flex card-footer pa-4">
                    <v-btn
                        color="yellow"
                        width="130px"
                        :disabled="disabledVoltar"
                        @click="voltar"
                        class="new-button"
                        dark
                    >
                        <v-icon left>mdi-chevron-left-circle</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                        color="error"
                        width="130px"
                        class="new-button"
                        @click="dialogForm = false"
                    >
                        <v-icon left>mdi-close</v-icon>
                        Fechar
                    </v-btn> -->
                    <v-btn
                        color="primary"
                        width="130px"
                        @click="proximo"
                        class="new-button"
                        v-if="!acordo.fnLeitura || passo < 5"
                    >
                        {{ botao }}
                        <v-icon right>{{ botaoIco }}</v-icon>
                    </v-btn>
                </div>
            </v-stepper>
        </v-card>
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 6"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
  
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
  
        <v-dialog v-model="dialogoErro" persistent width="90%">
            <v-card>
                <v-card-title class="subtitle-1 error white--text">
                    Erro(s) ao Salvar Acordo(s)
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-data-table
                        :headers="listaHeaderErro"
                        :items="listaErro"
                        :items-per-page="5"
                        :options.sync="paginationErro"
                        class="elevation-1 data-table-2"
                    ></v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <download-csv
                        :data="listaErro"
                        :labels="listaErroLabels"
                        name="erros.csv"
                        delimiter=";"
                    >
                        <v-btn color="primary">
                            <v-icon left>mdi-microsoft-excel</v-icon>
                            Exportar
                        </v-btn>
                    </download-csv>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="fecharErros()">
                        <v-icon left>mdi-close</v-icon>
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
  </template>
  
  <script>
  import Cadastro from "./Cadastro.vue";
  import Filial from "./Filial.vue";
  import Desconto from "./Desconto.vue";
  import Produto from "./Produto.vue";
  import Planograma from "./Planograma.vue";
  import smartId from "smart-id"
  export default {
    name:'abaFormulario',
    inject: ["listaCampos"],
    components: {
        Cadastro,
        Filial,
        Desconto,
        Produto,
        Planograma,
    },
    data() {
        return {
            acordo: {
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                espaco_macro: null,
                id_fornecedor: null,
                fornecedor: null,
                fornecedorDesconto: null,
                bandeira: null,
                modelo_ck: null,
                flag_teste: false,
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                planograma: null,
                comentario: null,
                fnEditar: false,
                fnLeitura: false,
                preAcordo: false,
                id_acordo_title: 0,
                mecanismo_preco_id: 0,
                regioesSelecionadas: [],
                assinatura: null,
                metodo_criacao: 'PD',
                regioesClusters: []
            },
            usuarioTipo: 'rd',
            dialogForm: false,
            passo: 1,
            botao: "Próximo",
            botaoIco: "mdi-chevron-right",
            disabledVoltar: true,
            carregando: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            paginationErro: {},
            dialogoErro: false,
            listaHeaderErro: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            preco_fixo: [],
            clusters: ['POPULAR','SUPER POPULAR', 'HIBRIDA', 'NOBRE', 'SUPER NOBRE', 'AMPLA'],
            listaErro: [],
            listaErroLabels: { msg: "Descrição do Erro" },
            status: {},
            regra: {
                etapa1: [
                    () => {
                        if (this.passo > 1) {
                            return this.$refs.cadastro.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa2: [
                    () => {
                        if (this.passo > 2) {
                            return this.$refs.filial.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa3: [
                    () => {
                        if (this.passo > 3) {
                            return this.$refs.desconto.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa4: [
                    () => {
                        if (this.passo > 4) {
                            return this.$refs.produto.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa5: [
                    () => {
                        if (this.passo > 5) {
                            return this.$refs.planograma.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
            },
        };
    },
    async provide() {
        return {
            listaCampos: await this.listaCampos,
        };
    },
    computed: {
        formTitulo() {
            let _idAcordo = this.acordo.id_acordo_title;
            return (
                "Acordo " +
                _idAcordo +
                (this.acordo.fnLeitura ? " - Somente Leitura" : "")
            );
        },
    },
    watch: {
        "acordo.preAcordo": function (value) {
            console.log(value);
            if (this.passo == 3 && value === true) {
                this.botao = "Salvar";
                this.botaoIco = "mdi-content-save";
            } else {
                this.botao = "Próximo";
                this.botaoIco = "mdi-chevron-right";
                this.acordo.visivel = null;
            }
        },
        passo(valor) {
            if (this.acordo.preAcordo == true && valor == 3) {
                this.botao = "Salvar";
                this.botaoIco = "mdi-content-save";
                this.carregaDesconto(this.$refs.filial.errosFiliais);

                return;
            }
            if (valor == 1) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
            }
            if (valor == 2) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
  
                // this.acordo = { ...this.acordo, ...this.$refs.cadastro.acordo };
  
                this.carregaFilial();
                // this.$refs.produto.setAcordo(this.acordo);
                // this.$refs.planograma.setAcordo(this.acordo);
  
                // if (this.$refs.filial.dados.length === 0) {
                //     this.$refs.filial.filtrar(true, true);
                // }
            } else if (valor == 3) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
                this.$refs.filial.validate();
                this.carregaDesconto(this.$refs.filial.errosFiliais);
  
                // this.$refs.produto.buscaProdutos();
            } else if (valor == 4) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
                this.carregaProduto();
    
            } else if (valor == 5) {
                // this.carregaPlanograma();
                this.botao = "Salvar";
                this.botaoIco = "mdi-check-circle";
                this.resumoAcordo();
            }
            this.disabledVoltar = this.passo == 1 ? true : false;
        },
    },
  
    methods: {
        getAcordo() {
            return this.acordo;
        },
        async carregaFilial() {
            await this.$refs.filial.setAcordo(this.preco_fixo);
            console.log("filial carregada");
        },
        async carregaDesconto(errosFiliais) {
            var somaFiliais = this.$refs.filial.somaFiliais;
            var somaFiliaisValor = this.$refs.filial.somaFiliaisValor;
            const quantidadeFiliais = this.$refs.filial.filiais.length;
            await this.$refs.desconto.criaDesconto(
                this.acordo,
                somaFiliais,
                somaFiliaisValor,
                quantidadeFiliais,
                errosFiliais
            );
            console.log("desconto carregado");
        },
        async carregaProduto() {
            await this.$refs.produto.buscaProdutos();
        },
        getPeriodo(campo = "quantidade") {
            var mesAtual = new Date(this.acordo.mesInicial + "-15");
            var mesFinal = new Date(this.acordo.mesFinal + "-15");
  
            var periodo = [];
  
            while (mesAtual <= mesFinal) {
                periodo.push(
                    campo +
                        "_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1)
                );
  
                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }
  
            return periodo;
        },
        novo(usuarioTipo) {
            console.log('novo')
            this.acordo = {
                negociacao_comercial: 'NÃO',
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                espaco_macro: null,
                id_fornecedor: null,
                fornecedor: null,
                fornecedorDesconto: null,
                bandeira: null,
                modelo_ck: null,
                flag_teste: false,
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                planograma: null,
                comentario: null,
                fnNovo: true,
                fnEditar: false,
                fnLeitura: false,
                fnReplicar: false,
                preAcordo: false,
                id_acordo_title: 0,
                ponta_tematica: false,
                mecanismo_preco_id: 0,
                metodo_criacao: 'PD',
                assinatura: smartId.make(),
                moveisDermo: [],
                clustersNomes: [],
                clusters: [],
                produtos_por_cluster: false,
                regioesSelecionadas: [],
                regioesClusters: [],
                id_acordo_ecommerce: []
            };
            this.usuarioTipo = usuarioTipo
            this.dialogForm = true;
            this.passo = 1;
            this.disabledVoltar = true;
            this.botao = "Próximo";
            this.botaoIco = "mdi-chevron-right";
  
            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.resetValidation();
                this.$refs.filial.limpaFiltro();
                this.$refs.filial.limpaSelecionadas();
                this.$refs.produto.limpaProdutos();
                this.$refs.planograma.resetValidation();
                this.$refs.desconto.limpaDescontos();
            } catch (error) {
                //console.log(error)
            }
            this.acordo.mesInicial = this.$moment().format("YYYY-MM");
            this.acordo.mesFinal = this.$moment().format("YYYY-MM");
            
            this.$gtag.pageview({ page_title: `Novo Acordo` })
  
            this.$gtag.screenview({
                app_name: 'RD_Trade',
                screen_name: 'Novo_Acordo',
            })
        },
        async editar(item, fnLeitura = false) {
  
            this.dialogForm = true;
            this.passo = 1;
            this.disabledVoltar = true;
            this.botao = "Próximo";
            this.botaoIco = "mdi-chevron-right";
  
            this.carregando = true;
  
            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.resetValidation();
                this.$refs.filial.limpaFiltro();
                this.$refs.filial.limpaSelecionadas();
                this.$refs.produto.limpaProdutos();
                this.$refs.planograma.resetValidation();
                this.$refs.desconto.limpaDescontos();
            } catch (error) {
                //console.log(error)
                
            }
  
            var data = {
                id_acordo: item.id_acordo,
            };
  
            var response = await this.$http().post("/acordo/acordo", {
                data: data,
            });
  
            var contrato = response.data.contrato;
            var acordo = response.data.acordo;
            var fornecedor = response.data.fornecedor;
            var idAcordos = (acordo.metodo_criacao == 'RG' && (!fnLeitura)) ? response.data.idAcordosLote : response.data.idAcordos;
            
            
            var lote = response.data.lote;
            this.status = response.data.status;
        
            this.acordo = {
                negociacao_comercial: item.negociacao_comercial,
                mesInicial: item.mes_acordo,
                mesFinal: item.termino,
                id_espaco: contrato.id_espaco,
                id_fornecedor: contrato.id_fornecedor,
                fornecedor: fornecedor.nome,
                fornecedorDesconto: fornecedor.desconto,
                bandeira: contrato.bandeira,
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                visivel: acordo.visivel,
                planograma: null,
                comentario: acordo.comentario,
                fnEditar: true,
                fnLeitura: fnLeitura,
                fnReplicar: false,
                fnNovo: false,
                idAcordos: idAcordos || [],
                idAcordosLote: response.data.idAcordosLote || [],
                acordosLote: response.data.acordosLote || [],
                espacosLote: response.data.espacosLote || [],
                testeirasLote: response.data.testeirasLote || [],
                modelo_ck: acordo.check_out_modelo,
                flag_teste: acordo.flag_teste,
                clusters: acordo.clusters || [],
                clustersNomes: [],
                clusters_acordo: acordo.clusters_acordo || [],
                preAcordo: false,
                idStatus: item.id_status,
                id_acordo_title: item.id_acordo,
                desconto: [],
                id_espaco_tematico: contrato.id_espaco_tematico,
                ponta_tematica: contrato.id_espaco_tematico > 0,
                valor: acordo.valor,
                prateleira_nome: acordo.prateleira_nome,
                prateleira: acordo.prateleira,
                id_ponta_tematica: acordo.id_ponta_tematica,
                mecanismo_preco_id: acordo.mecanismo_preco_id,
                metodo_criacao: acordo.metodo_criacao,
                produto: lote.produto ? lote.produto : acordo.produto,
                lote: lote,
                assinatura: smartId.make(),
                moveisDermo: [],
                testeira: acordo.testeira,
                id_entregavia: acordo.id_entregavia,
                preco_fixo: response.data.preco_fixo,
                marca: acordo.marca,
                id_categoria_produto: acordo.id_categoria_produto,
                produtos_por_cluster: acordo.espaco_macro == 'Cubo' ||  acordo.espaco_macro == 'Fila Única',
                espaco_macro: acordo.espaco_macro,
                regioesSelecionadas: [],
                regioesClusters: [],
                id_acordo_ecommerce: acordo.id_acordo_ecommerce
            };
            this.preco_fixo = response.data.preco_fixo
            console.log('acordo',this.acordo,response)
            if (item.id_status === 30) {
                this.acordo.preAcordo = true;
            }
            this.statusAcesso();
  
            await this.$refs.cadastro.setAcordo(this.acordo);
            await this.carregaFilial()
            await this.carregaProduto()
            // await Promise.all([
            //     this.carregaFilial(),
            //     this.carregaProduto(),
            // ])
            await this.carregaDesconto(),



            this.carregando = false;
  
            if(fnLeitura){
                this.$gtag.pageview({ page_title: `Exibir acordo (${item.id_acordo})` })
            }else {
                this.$gtag.pageview({ page_title: `Editar acordo (${item.id_acordo})` })
            }
        },
        async replicarAcordo(item, tipoReplicacao) {
            this.dialogForm = true;
            this.passo = 1;
            this.disabledVoltar = true;
            this.botao = "Próximo";
            this.botaoIco = "mdi-chevron-right";
  
            this.carregando = true;
  
            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.resetValidation();
                this.$refs.filial.limpaFiltro();
                this.$refs.filial.limpaSelecionadas();
                this.$refs.produto.limpaProdutos();
                this.$refs.planograma.resetValidation();
            } catch (error) {
                //console.log(error)
            }
  
            var data = {
                id_acordo: item.id_acordo,
            };
  
            var response = await this.$http().post("/acordo/acordo", {
                data: data,
            });
  
            const contrato = response.data.contrato;
            const acordo = response.data.acordo;
            const fornecedor = response.data.fornecedor;
            const idAcordos = response.data.idAcordos;
            // var idAcordos = (acordo.metodo_criacao == 'RG' && (!fnLeitura)) ? response.data.idAcordosLote : response.data.idAcordos;
            var lote = response.data.lote;
  
            this.status = response.data.status;
            console.log(lote);
            this.acordo = {
                negociacao_comercial: item.negociacao_comercial,
                mesInicial: item.mes_acordo,
                mesFinal: item.termino,
                id_espaco: contrato.id_espaco,
                id_fornecedor: contrato.id_fornecedor,
                fornecedor: fornecedor.nome,
                fornecedorDesconto: fornecedor.desconto,
                bandeira: contrato.bandeira,
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                visivel: acordo.visivel,
                planograma: acordo.planograma,
                comentario: acordo.comentario,
                fnEditar: false,
                fnLeitura: false,
                fnReplicar: true,
                tipoReplicacao: tipoReplicacao,
                idAcordos: idAcordos || [],
                modelo_ck: acordo.check_out_modelo,
                flag_teste: acordo.flag_teste,
                // clusters: acordo.clusters || [],
                // clusters_acordo: acordo.clusters_acordo || [],
                preAcordo: false,
                id_acordo_title: " replicado de " + item.id_acordo,
                // id_espaco_tematico: contrato.id_espaco_tematico,
                // ponta_tematica: contrato.id_espaco_tematico > 0,
                // valor: acordo.valor,
                // prateleira_nome: acordo.prateleira_nome,
                // prateleira: acordo.prateleira,
                // id_ponta_tematica: acordo.id_ponta_tematica,
                mecanismo_preco_id: acordo.mecanismo_preco_id,
                // prateleira_nome: item.prateleira_nome,
                // prateleira: acordo.prateleira,
                // id_ponta_tematica: item.id_ponta_tematica,
                // mecanismo_preco_id: item.mecanismo_preco_id,
                metodo_criacao: acordo.metodo_criacao,
                produto: lote.produto ? lote.produto : acordo.produto,
                // lote: acordo.lote,
                assinatura: smartId.make(),
                moveisDermo: [],
                testeira: acordo.testeira,
                produtos_por_cluster: acordo.espaco_macro == 'Cubo' ||  acordo.espaco_macro == 'Fila Única',
                id_entregavia: acordo.id_entregavia,
                id_categoria_produto: acordo.id_categoria_produto,
                espaco_macro: acordo.espaco_macro

            };
            if (item.id_status === 30) {
                this.acordo.preAcordo = true;
            }
            if (tipoReplicacao === "bandeira") {
                this.acordo.planograma = null;
                if (contrato.bandeira === "DROGASIL") {
                    this.acordo.bandeira = "RAIA";
                }
                if (contrato.bandeira === "RAIA") {
                    this.acordo.bandeira = "DROGASIL";
                }
            }
            if (tipoReplicacao === "checkout") {
              this.acordo.planograma = null;
              if (acordo.check_out_modelo === "NOVO") {
                this.acordo.modelo_ck = "ANTIGO";
              }
              if (acordo.check_out_modelo === "ANTIGO") {
                this.acordo.modelo_ck = "NOVO";
              }
              if (acordo.check_out_modelo === "") {
                this.acordo.modelo_ck = null;
              }
            }
  
            this.statusAcesso();
  
            //setTimeout(() => this.$refs.cadastro.setAcordo(this.acordo), 100);
            await this.$refs.cadastro.setAcordo(this.acordo);
  
            this.carregando = false;
        },
        proximo() {
            console.log("proximo", this.passo);
            if (this.passo == 5) {
                return this.salvarAcordo();
            } else if (this.acordo.preAcordo && this.passo == 3) {
                return this.salvarAcordo();
            } else {
                this.passo++;
            }
        },
        proximoOld() {
            if (this.passo == 1) {
                if (!this.$refs.cadastro.validate()) {
                    return;
                }
  
                // this.acordo = { ...this.acordo, ...this.$refs.cadastro.acordo };
  
                // this.$refs.filial.setAcordo(this.acordo);
                // this.$refs.produto.setAcordo(this.acordo);
                // this.$refs.planograma.setAcordo(this.acordo);
  
                // if (this.$refs.filial.dados.length === 0) {
                //     this.$refs.filial.filtrar(true, true);
                // }
            }
  
            if (this.passo == 2) {
                var somaFiliais = this.$refs.filial.somaFiliais;
                var somaFiliaisValor = this.$refs.filial.somaFiliaisValor;
  
                if (!this.acordo.fnLeitura) {
                    if (somaFiliais.length == 0) {
                        this.alerta = true;
                        this.alertaColor = "error";
                        this.alertaTimeout = 3000;
                        this.alertaTexto =
                            "Selecione pelo menos uma Filial para cada mês.";
                        return;
                    } else {
                        for (const p of this.getPeriodo()) {
                            if (somaFiliais[0][p] == 0) {
                                this.alerta = true;
                                this.alertaColor = "error";
                                this.alertaTimeout = 3000;
                                this.alertaTexto =
                                    "Selecione pelo menos uma Filial para cada mês.";
                                return;
                            }
                        }
                    }
                }
  
                this.$refs.desconto.criaDesconto(
                    this.acordo,
                    somaFiliais,
                    somaFiliaisValor
                );
            }
  
            if (this.passo == 3) {
                var desconto = this.$refs.desconto.desconto;
                for (const d of desconto) {
                    if (d.mes != "Total" && d.investimentoValor < 0) {
                        this.alerta = true;
                        this.alertaColor = "error";
                        this.alertaTimeout = 3000;
                        this.alertaTexto =
                            "Não pode haver investimentos menores que zero.";
                        return;
                    }
  
                    if (
                        (d.desconto && d.desconto != "" && d.desconto != 0) ||
                        (d.percentual && d.percentual != "")
                    ) {
                        if (!d.justificativa || d.justificativa == "") {
                            this.alerta = true;
                            this.alertaColor = "error";
                            this.alertaTimeout = 3000;
                            this.alertaTexto =
                                "Descontos fora do padrão devem possuir justificativa.";
                            return;
                        }
                    }
                }
  
                if (this.$refs.produto.produto.length === 0) {
                    this.$refs.produto.buscaProdutos();
                }
  
                if (this.acordo.preAcordo) {
                    this.acordo.visivel = "Não";
                    this.$refs.planograma.setAcordo(this.acordo);
                    this.salvarAcordo();
                    return;
                }
            }
  
            if (this.passo == 4) {
                var somaProdutoSalvo = this.$refs.produto.somaProdutoSalvo;
  
                if (somaProdutoSalvo.length == 0) {
                    this.alerta = true;
                    this.alertaColor = "error";
                    this.alertaTimeout = 3000;
                    this.alertaTexto = "Selecione pelo menos um Produto.";
                    return;
                } else {
                    if (somaProdutoSalvo[0].produto == 0) {
                        this.alerta = true;
                        this.alertaColor = "error";
                        this.alertaTimeout = 3000;
                        this.alertaTexto = "Selecione pelo menos um Produto.";
                        return;
                    }
                }
  
                this.botao = "Salvar";
                this.botaoIco = "mdi-content-save";
            }
  
            if (this.passo == 5) {
                if (!this.$refs.planograma.validate()) {
                    return;
                }
  
                this.salvarAcordo();
            } else {
                this.passo++;
  
                this.statusAcesso();
            }
  
            this.disabledVoltar = this.passo == 1 ? true : false;
        },
        voltar() {
            // if (this.passo == 5) {
            //     this.acordo = { ...this.$refs.planograma.acordo };
            // }
  
            this.passo--;
  
            // if (this.passo < 5) {
            //     this.statusAcesso();
  
            //     this.botao = "Próximo";
            //     this.botaoIco = "mdi-chevron-right";
            // }
  
            // this.disabledVoltar = this.passo == 1 ? true : false;
        },
        async salvarAcordo() {
            
            if (this.acordo.preAcordo) {
                if (
                    !this.$refs.cadastro.validate() ||
                    !this.$refs.filial.validate() ||
                    !this.$refs.desconto.validate()
                ) {
                    return this.$dialog.notify.error("Formulário com erros", {
                        position: "top-right",
                        timeout: 5000,
                    });
                }
                this.acordo.visivel = "Não";
  
            } else {
                if (
                    !this.$refs.cadastro.validate() ||
                    !this.$refs.filial.validate() ||
                    !this.$refs.desconto.validate() ||
                    !this.$refs.produto.validate() ||
                    !this.$refs.planograma.validate()
                ) {
                    return this.$dialog.notify.error("Formulário com erros", {
                        position: "top-right",
                        timeout: 5000,
                    });
                }
            }

            this.carregando = !this.carregando;
  
            var data = {};
  
            data.acordo = this.acordo;
            // data.acordo.preAcordo = this.acordo.preAcordo;
  
            console.log(data.acordo)
  
            data.desconto = this.$refs.desconto.desconto.map((a) => ({ ...a }));
  
            for (const e of data.desconto) {
                e.percentual =
                    e.percentual === "" 
                    //|| e.percentual == "0"
                        ? null
                        : e.percentual;
                e.desconto =
                    e.desconto === "" || e.desconto == "0.00"
                        ? null
                        : e.desconto;
            }

            // console.log(this.$refs.filial.filiais,'salvarAcordoInicio')

            data.filiais = this.$refs.filial.filiais.map((a) => {
                let obj = {
                    id_filial: a.id_filial,
                    id_java: a.id_java,
                    custo: a.custo,
                    regiao: a.regiao,
                    id_espaco: a.id_espaco
                };
  
                for (const e of this.getPeriodo()) {
                    obj[e] = a[e];
                }
  
                return { ...obj };
            });
            // console.log(data.filiais,'salvarAcordoIniciox')
  
            data.acordo.clusters = [
                ...new Set(this.$refs.filial.filiais.map((e) => e.id_cluster)),
            ];
  
            // data.produto = this.$refs.produto.produtoSalvo.map((a) => ({
            //     id_produto: a.id_produto,
            //     vb: a.vb,
            // })); 
            if (data.acordo.metodo_criacao == 'RG') {
                data.produto = this.$refs.produto.produtoSalvo.map((a) => ({
                    id_produto: a.id_produto,
                    // vb: a.vb,
                    regiao: a.regiao
                }));
            } else if (data.acordo.metodo_criacao == 'MD') {
                data.produto = this.$refs.produto.produtoSalvo.map((a) => {

                    delete a.marca;
                    delete a.nome;
                    delete a.ativo_inativo;
                    delete a.curva;
                    
                    return a;
                });
           

            } else {
                if ((data.acordo.produtos_por_cluster)) {
                    console.log('aqui',this.clusters,this.$refs.produto.produtoSalvo)
                    data.produto = this.$refs.produto.produtoSalvo.map((a) => {
                        var item = {id_produto: a.id_produto}
                        for (const cluster of this.clusters) {
                            item[cluster] = a[cluster]
                        }
                        return item
                    });
                    console.log('passou')
                    // data.produto = this.$refs.produto.produtoSalvo.map((a) => ({
                    //     id_produto: a.id_produto,
                    //     vb: a.vb,
                    // }));
                } else {
                    data.produto = this.$refs.produto.produtoSalvo.map((a) => ({
                        id_produto: a.id_produto,
                        vb: a.vb,
                    }));
                }
            }
            // acordo.acordosLote = this.acordo.acordosLote;
  
            var formData = new FormData();
            // formData.append("files", this.$refs.planograma.value.planograma);
            if (data.acordo.metodo_criacao == 'RG') {
                // console.log(this.$refs.planograma.planogramas)
                if (this.$refs.planograma.planogramas) {
                    for (const p of this.$refs.planograma.planogramas) {
                        if (p.file)
                            formData.append("files", p.file, p.regiao.replace('/','*barra*') + '|' + p.file.name)
                    }
                    this.$refs.planograma.planogramas = null
                }
            } else if (data.acordo.metodo_criacao == 'MD') {
                // console.log(this.$refs.planograma.planogramas)
                if (this.$refs.planograma.planogramas) {
                    for (const p of this.$refs.planograma.planogramas) {
                        // console.log(p)
                        if (p.file)
                            formData.append("files", p.file, p.slug + '|' + p.file.name)
                    }
                    this.$refs.planograma.planogramas = null
                }
            } else {                
                // if (this.$refs.planograma.value.planograma.value.planograma)
                formData.append("files", this.$refs.planograma.value.planograma);
            }
            formData.append("acordo", JSON.stringify(data.acordo));
            formData.append("desconto", JSON.stringify(data.desconto));
            formData.append("produto", JSON.stringify(data.produto));

            let pages =
                1 + (data.filiais.length - (data.filiais.length % 10)) / 10;
  
            for (let i = 0; i < pages; i++) {
                formData.append(
                    `filiais[${i}]`,
                    JSON.stringify(data.filiais.slice(i * 10, (i + 1) * 10))
                );
            }
  
            var response = {};
  
            if (data.acordo.fnEditar) {
                response = await this.$http().post("/acordo/editar", formData);
            } else {
                response = await this.$http().post("/acordo/salvar", formData);
            }
  
            this.$emit("filtrar");
  
            if (response.data.sucesso) {
                this.alerta = true;
                this.alertaColor = "success";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
  
                setTimeout(() => {
                    this.alerta = false;
                    this.carregando = false;
                    this.dialogForm = false;
                }, 1000);
            } else {
                if (response.data.msg) {
                    this.alerta = true;
                    this.alertaColor = "error";
                    this.alertaTimeout = -1;
                    this.alertaTexto = response.data.msg;
  
                    this.carregando = false;
                } else {
                    this.dialogoErro = true;
                    this.listaErro = response.data.erros;
  
                    this.paginationErro.page = 1;
                }
            }
        },
        fecharErros() {
            this.dialogoErro = false;
            this.carregando = false;
            this.dialogForm = false;
        },
        resumoAcordo() {
            const totalInvestimento = this.$refs.desconto.desconto.find(
                (e) => e.mes == "Total"
            );
            const resumo = {
                filiais: this.$refs.filial.filiais.length,
                investimento: totalInvestimento?.investimento,
                produtos: this.$refs.produto.produtoSalvo.length,
            };
            this.$refs.planograma.setResumo(resumo);
        },
        statusAcesso() {
            if (this.acordo.fnLeitura) {
                return;
            }
  
            var passoProcesso = [
                null,
                "cadastro",
                "filial",
                "desconto",
                "produto",
                "planograma",
            ];
  
            var semAcesso = false;
  
            if (this.acordo.fnEditar) {
                semAcesso = !this.status["editar_" + passoProcesso[this.passo]];
  
                if (semAcesso) {
                    this.alerta = true;
                    this.alertaColor = "info";
                    this.alertaTimeout = 3000;
                    this.alertaTexto =
                        "Esta etapa não pode ser editada no status atual do acordo.";
                }
            }
  
            setTimeout(
                () =>
                    this.$refs[passoProcesso[this.passo]].setSemAcesso(
                        semAcesso
                    ),
                200
            );
        },
    },
  };
  </script>
  <style>
  .card-econ {
    height: 100%;
    background: red;
  }
  .etapas-conteudo {
    height: 100%;
    overflow: auto;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .card-footer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
  }
  .fix-flex {
    flex-grow: 0;
    flex-shrink: 1;
  }
  </style>
  <style lang="scss">
  .dialog-pdv {
    height: 100%;
    max-height: calc(100% - 50px) !important;
    .v-stepper__header {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
    }
    .v-stepper__content {
        box-shadow: unset !important;
    }
    .v-stepper__step {
        padding: 10px;
        margin: 15px;
        background-color: #f5f5f5;
        border-radius: 8px;
        min-width: 200px;
        justify-content: center;
        .v-stepper__label {
            color: #000 !important;
            font-weight: 500;
            font-size: 0.9rem;
        }
        .v-stepper__step__step {
            background-color: var(--v-primary-base) !important;
            height: 20px;
            width: 20px;
            min-width: 20px;
        }
        &:hover .v-stepper__label {
            text-shadow: unset !important;
        }
        &.v-stepper__step--error {
            .v-stepper__label {
                color: red !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                .v-icon {
                    color: red;
                    font-size: 24px;
                }
            }
        }
        &.v-stepper__step--active {
            background-color: var(--v-primary-base);
            color: white;
            &:hover {
                background-color: var(--v-primary-base);
            }
            .v-stepper__label {
                color: #fff !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                color: var(--v-primary-base) !important;
            }
        }
    }
  }
  </style>