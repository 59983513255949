<template>
    <v-container fluid class="mt-0 pt-0">
        <v-card class="px-5 py-3 br-15" elevation="0">
            <v-row>
                <v-col cols="6">
                    <v-toolbar-title class="page-title"
                        >Acordos Comerciais</v-toolbar-title
                    >
                </v-col>
                <v-col cols="4" class="d-flex justify-end">
                    <v-btn
                        @click="criarAcordo"
                        class="new-button mx-3"
                        color="primary"
                        v-if="usuarioTipo == 'rd'"
                    >
                        <v-icon left>mdi-view-grid-plus</v-icon> Criar acordo
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                class="new-button w-100"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportar()">
                                <v-list-item-title
                                    >Exportação Acordos</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="exportarProdutos()">
                                <v-list-item-title>Exportação Produtos</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <Filtro ref="filtro" @filtrar="filtrar" @limpar="limpar" />
        </v-card>
        <Indicadores
            class="my-3"
            :indicadores="indicadores"
            :filtro="$refs.filtro"
            v-if="usuarioTipo == 'rd'"
        ></Indicadores>
        <v-card elevation="0" class="br-15 pa-5 involucroEcommerce">
            <v-data-table
                v-model="acordoSelecionado"
                :headers="headers"
                :header-props="{
                    dark: true,
                }"
                :server-items-length="totalAcordos"
                :items="acordos"
                :items-per-page="5"
                item-key="id_acordo"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100, 500, -1],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }"
                :single-select="singleSelect"
                :options.sync="pagination"
                show-select
                class="tabela paginacao-esquerda flex-table"
            >
                <template
                    v-slot:[`item.data-table-select`]="{
                        item,
                        isSelected,
                        select,
                    }"
                >
                    <v-simple-checkbox
                        :ripple="false"
                        :value="isSelected"
                        color="primary"
                        :disabled="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                        "
                        :off-icon="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                                ? '$checkboxLocked'
                                : 'mdi-checkbox-blank-outline'
                        "
                        :on-icon="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                                ? '$checkboxLocked'
                                : 'mdi-checkbox-marked'
                        "
                        @input="select($event)"
                    ></v-simple-checkbox>
                </template>
                <template
                    v-for="header in headers.filter((header) =>
                        header.hasOwnProperty('formatter')
                    )"
                    v-slot:[`item.${header.value}`]="{ header, value }"
                >
                    {{ header.formatter(value) }}
                </template>
                <template v-slot:[`item.calories`]="{ item }">
                    <v-chip :color="getColor(item.calories)" dark>
                        {{ item.calories }}
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu
                        offset-y
                        min-width="180"
                        content-class=" acordo-menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                color="#f2f2f2"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                                style="min-width: unset"
                            >
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item
                                    v-if="
                                        statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) && fnAprovar
                                        && usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.reprovar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentRemove</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Reprovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) && fnAprovar
                                        && usuarioTipo == 'rd'

                                    "
                                    link
                                    @click="$refs.dialogo.aprovar(item)"
                                    class="hover-red"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentCheck</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Aprovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.mes_acordo >= mesAtual &&
                                        statusAcesso(item.id_status, 'editar') 
                                        &&  usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.formulario.editar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    class="pl-2 mr-0"
                                    v-if="['rd', 'cubo'].includes(usuarioTipo)"
                                    link
                                    @click="$refs.dialogo.historico(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Histórico</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="
                                        $refs.dialogo.dialogResumo(item, true)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exibir Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.termino == item.mes_acordo &&
                                        item.termino >= mesAnterior &&
                                        statusAcesso(item.id_status, 'renovar')
                                        && usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.renovacao(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-update</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Renovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.obs_departamento  && usuarioTipo == 'rd'"
                                    link
                                    @click="
                                        $refs.dialogo.dialogDepartamento(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-clipboard-text-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Obs.
                                            Departamento</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.obs_contrato"
                                    link
                                    @click="$refs.dialogo.dialogContrato(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-clipboard-text</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Obs. Contrato</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        statusAcesso(item.id_status, 'cancelar')
                                        && usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.cancelar(item)"
                                    class="hover-red"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentRemove</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Cancelar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        fnLiberar &&
                                        statusAcesso(
                                            item.id_status,
                                            'liberar'
                                        ) && item.mes_acordo == mesAtual
                                        && usuarioTipo == 'rd'

                                    "
                                    link
                                    @click="
                                        dialogoLiberarAcordos(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentCheck</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Liberar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
            </v-data-table>

            <div 
            v-if="  usuarioTipo == 'rd'"
                class="
                    table-footer-prepend
                    d-flex
                    pl-2
                    pt-4
                    align-center
                    justify-end
                "
            >
                <v-btn
                    v-if="!fnLiberar"
                    class="new-button ml-5"
                    :disabled="
                        !acordoSelecionado.filter((e) =>
                            statusAcesso(e.id_status, 'aprovar_reprovar')
                        ).length || !fnAprovar
                    "
                    color="primary"
                    @click="$refs.dialogo.aprovar()"
                >
                    <v-icon left>mdi-check-circle</v-icon> Aprovar Acordos
                </v-btn>
                <v-btn
                    class="new-button ml-5"
                    v-if="!fnLiberar"
                    :disabled="
                        !acordoSelecionado.filter((e) =>
                            statusAcesso(e.id_status, 'aprovar_reprovar')
                        ).length || !fnAprovar
                    "
                    color="error"
                    @click="$refs.dialogo.reprovar()"
                >
                    <v-icon left>mdi-close-circle</v-icon> Reprovar Acordos
                </v-btn>
                <v-btn
                    v-if="fnLiberar"
                    class="new-button ml-5"
                    :disabled="
                        !acordoSelecionado.filter(
                            (e) =>
                                statusAcesso(e.id_status, 'liberar') &&
                                e.mes_acordo == mesAtual &&
                                        usuarioTipo == 'rd' 
                        ).length
                    "
                    color="primary"
                    @click="dialogoLiberarAcordos()"
                >
                    <v-icon left>mdi-check</v-icon> Liberar acordos
                </v-btn>
            </div>
        </v-card>
        <dialogo
            ref="dialogo"
            @aprovarAcordo="aprovarAcordo"
            @liberarAcordo="liberarAcordo"
            @filtrar="filtrar"
            @renovar="renovar"
        ></dialogo>
        <formulario ref="formulario" @filtrar="filtrar" />
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="alerta" :color="alertaColor" :timeout="alertaTimeout" top style="z-index: 400">
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
        <v-dialog
            content-class="br-15"
            v-model="dialogErroLiberacao"
            width="800"
            :z-index="400"
            >
            <v-card>
                <v-toolbar elevation="0" dark class="error white--text fix-flex">
                <v-toolbar-title> Erros durante a liberação </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    light
                    @click="dialogErroLiberacao = false"
                >
                    <v-icon size="18px" color="error">mdi-close</v-icon>
                </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                <v-data-table
                    :headers="headerErrosImportacao"
                    class="elevation-0 tabela"
                    :items="errosImportacao"
                    :items-per-page="10"
                    :footer-props="tableConfigImportacao"
                    color="error"
                ></v-data-table>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <download-csv
                    :data="errosImportacao"
                    :labels="errosLabelsImportacao"
                    name="erros_planejamento_ecommerce.csv"
                    delimiter=";"
                >
                    <v-btn class="new-button px-4 mb-2" color="primary">
                    <v-icon class="mr-3">mdi-microsoft-excel</v-icon>
                    Exportar erros em CSV
                    </v-btn>
                </download-csv>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </v-container>
</template>
<script>
import Filtro from "./Filtro.vue";
import Indicadores from "./Indicadores.vue";
import Formulario from "./formulario/Formulario.vue";
import Dialogo from "./Dialogo.vue";

export default {
    name: 'listaEcommmerceAcordos',
    components: {
        Filtro,
        Indicadores,
        Formulario,
        Dialogo,
    },
    data() {
        return {
            dialogErroLiberacao: false,
            errosImportacao: [],
            headerErrosImportacao: [
                { text: "Código", value: "codigo", align: "center", with: "100px" },
                { text: "Descrição", value: "msg", class: "th-separador" },
            ],
            errosLabelsImportacao: {
                linha: "Código",
                msg: "Descrição",
            },
            tableConfigImportacao: {
                itemsPerPageOptions: [10, 15, 25, 50, 100],
                itemsPerPageText: "Linhas por páginas",
                ofText: "de",
                pageText: "{0}-{1} de {2}",
            },
            alerta: false,
            alertaTexto: '',
            alertaTimeout: -1,
            alertaColor: 'error',
            singleSelect: false,
            fnLiberar: false,
            fnAprovar: false,
            acordoSelecionado: [],
            pagination: {},
            acordos: [],
            indicadores: {},
            totalAcordos: 0,
            carregando: false,
            headers: [
                {
                    text: "Menu",
                    value: "actions",
                    sortable: false,
                    class: "th-separador  min-width-100 ",
                    align: "center",
                },
                {
                    text: "Acordo",
                    value: "id_acordo",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Contrato",
                    value: "id_contrato",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Acordo RD",
                    value: "acordo_rd",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Contrato RD",
                    value: "contrato",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Mês",
                    value: "mes",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Ano",
                    value: "ano",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Parcela",
                    value: "parcela",
                    sortable: false,
                    class: "th-separador min-width-100",
                    align: "center",
                },

                {
                    text: "Status",
                    value: "status",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Just. Desconto",
                    value: "justificativa",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Just. Reprov.",
                    value: "justificativa_reprovacao",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Just. Cancel.",
                    value: "justificativa_cancelamento",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Bandeira",
                    value: "bandeira",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Fornecedor",
                    value: "fornecedor",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Espaço",
                    value: "espaco",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Plano",
                    value: "plano",
                    class: "th-separador min-width-100",
                    align: "center",
                },
                {
                    text: "Valor",
                    value: "valor",
                    class: "th-separador min-width-100",
                    align: "center",
                    formatter: this.formataReal,
                },
                {
                    text: "Desconto padrão",
                    value: "desconto_padrao",
                    class: "th-separador min-width-100",
                    align: "center",
                    formatter: this.formataPorcentagem,
                },
                {
                    text: "Desconto percentual",
                    value: "desconto_percentual",
                    class: "th-separador min-width-100",
                    align: "center",
                    formatter: this.formataPorcentagem,
                },
                {
                    text: "Desconto total",
                    value: "desconto_total",
                    class: "th-separador min-width-100",
                    align: "center",
                    formatter: this.formataReal,
                },
                {
                    text: "Investimento",
                    value: "investimento",
                    class: "th-separador min-width-100",
                    align: "center",
                    formatter: this.formataReal,
                },
                {
                    text: "Usuário que Criou",
                    value: "usuario_criacao",
                    sortable: true,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Produtos editados",
                    value: "produto_editado",
                    // sortable: true,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cód. Fornecedor",
                    value: "codigo",
                    align: "center",
                    class: "th-separador   ",
                },  
            ],
        };
    },

    methods: {
        formataReal(valor) {
            return Number(valor).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        formataPorcentagem(valor) {
            return Number(valor/100).toLocaleString("pt-br", {
                style: "percent",
                minimumFractionDigits: 2,
            });
        },

        getColor(calories) {
            if (calories > 400) return "red";
            else if (calories > 200) return "orange";
            else return "green";
        },
        criarAcordo() {
            this.$refs.formulario.novo();
        },
        async listaCampos() {
            const response = await this.$http().post(
                "/acordoEcommerce/listaCampos",
                {}
            );

            return response.data;
        },
        limpar() {
            this.statusIndicadores = [];
            this.filtrar();
            // this.fnLiberar = false;
        },

        async filtrar(paginaInicial = true) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            this.acordoSelecionado = [];

            const filtro = this.$refs.filtro.filtro;
            const data = {};

            data.mesInicial = filtro.mesInicial;

            data.mesFinal = filtro.mesFinal;

            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1;

            data.bandeira = filtro.bandeira;
            data.id_espaco = filtro.id_espaco;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo = filtro.id_acordo ? [filtro.id_acordo] : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_usuario_criacao = filtro.id_usuario_criacao ? filtro.id_usuario_criacao : [];
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];

            if (
                data.id_status.length > 0 &&
                data.id_status.every((status) => status === 29)
            ) {
                console.log(data.id_status);
                this.fnLiberar = ((true) && ([1,2].includes(this.$store.getters.getUsuario.id_cargo)));
            } else {
                this.fnLiberar = false;
            }

            if (paginaInicial) this.pagination.page = 1;

            data.pagination = this.pagination;

            var response = await this.$http().post("/acordoEcommerce/lista", {
                data: data,
            });

            this.acordos = response.data.lista;
            this.totalAcordos = response.data.total;
            this.indicadores = response.data.indicadores;

            if (this.acordos.length == 0 && this.pagination.page > 1) {
                this.filtrar();
            }

            this.carregando = false;
        },
        async exportar() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            const filtro = this.$refs.filtro.filtro;

            const data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1;

            data.bandeira = filtro.bandeira;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo = filtro.id_acordo ? [filtro.id_acordo] : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];

            const response = await this.$http().post(
                "/acordoEcommerce/exportar",
                {
                    data: data,
                }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async exportarProdutos() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            const filtro = this.$refs.filtro.filtro;

            const data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1;

            data.bandeira = filtro.bandeira;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo = filtro.id_acordo ? [filtro.id_acordo] : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];

            const response = await this.$http().post(
                "/acordoEcommerce/exportarProdutos",
                {
                    data: data,
                }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        statusAcesso(id_status, acesso) {
            if (this.status) {
                if (id_status === 30 && acesso === "editar_planograma")
                    return false;

                var status = this.status.find((e) => e.id_status == id_status);

                return status[acesso];
            } else {
                return false;
            }
        },
        async liberarAcordo(acordos) {
            this.carregando = true;

            var response = await this.$http().post(
                "/acordoEcommerce/liberarAcordo",
                {
                    data: {
                        acordos: acordos,
                    },
                }
            );

            if (response.data.sucesso) {
                this.filtrar(false);
            } else {
                // this.alerta = true;
                // this.alertaColor = "error";
                // this.alertaTimeout = -1;
                // this.alertaTexto = response.data.msg;
                this.dialogErroLiberacao = true;
                this.errosImportacao = response.data.erros
            }

            this.acordoSelecionado = [];
            this.carregando = false;
        },
        dialogoLiberarAcordos(acordo = null) {
            let acordos = acordo ? [acordo] : this.acordoSelecionado.filter((e) =>
                this.statusAcesso(e.id_status, "liberar") 
            )
            const total = acordos.reduce((a,e) => 
                    a += parseFloat(e.investimento)
                ,0)
            acordos = acordos.map((a) => a.id_acordo);
            console.log(acordos)
            this.$refs.dialogo.liberar(acordos,total)
        },
        // liberarEmMassa() {
        //     const acordos = this.acordoSelecionado
        //         .filter((e) => this.statusAcesso(e.id_status, "liberar"))
        //         .map((a) => a.id_acordo);
        //     const investimento = this.acordoSelecionado.reduce((a,e) => a += parseFloat(e.investimento),0)    
        //     // console.log(investimento)
        //     // console.log("selecionados: ", this.acordoSelecionado);
        //     // console.log("acordos: ", acordos);
        //     this.$refs.dialogo.liberar(acordos,investimento);
        // },
        renovar(data) {
            this.$refs.formulario.editar(data.item);
        },
        async aprovarAcordo(data) {
            this.carregando = true;

            data.acordos = data.id_acordo
                ? [data.id_acordo]
                : this.acordoSelecionado
                      .filter((e) =>
                          this.statusAcesso(e.id_status, "aprovar_reprovar")
                      )
                      .map((a) => a.id_acordo);

            var response = await this.$http().post(
                "/acordoEcommerce/aprovarAcordo",
                {
                    data: data,
                }
            );

            if (response.data.sucesso) {
                this.filtrar(false);
                console.log("Success");
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }

            this.acordoSelecionado = [];
            this.carregando = false;
        },
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        mesAnterior() {
            return this.$moment().subtract(1, "months").format("YYYY-MM");
        },
        mesProximo() {
            return this.$moment().add(1, "months").format("YYYY-MM");
        },

        usuarioTipo() {
            const usuario = this.$store.getters.getUsuario;
            if (usuario.id_fornecedor) {
                return "fornecedor";
            }
            if(usuario.cargo == "Cubo"){
                return "cubo";
            }
            return "rd";
        },

    },
    async beforeCreate() {
        const response = await this.$http().post("/acordo/status", {});

        this.status = response.data;

        const response2 = await this.$http().post("/acordo/permissao", {
            id_menu: 2,
        });

        this.fnAprovar = response2.data;
    },
    provide() {
        return {
            listaCampos: this.listaCampos(),
        };
    },
    mounted() {
        this.filtrar();
    },
    watch: {
        pagination: {
            handler() {
                this.filtrar(false);
            },
            deep: true,
        },
    },
};
</script>
<style >
.paginacao-esquerda .v-data-footer {
    justify-content: flex-start;
}
.tabela {
    overflow: hidden;
    color: #808080 !important;
}
.tabela .v-data-table__wrapper {
    border-radius: 10px 10px 0 0;
    border: 1px solid #eaeaea !important;
}
.table-footer-prepend {
    margin-top: -58px;
    height: 58px;
}
.acordo-menu {
    box-shadow: 0 0 10px #80808050 !important;
    border-radius: 10px;
}
.acordo-menu .v-list-item__icon {
    margin-right: 0 !important;
}
.acordo-menu .v-list-item__title {
    color: #808080;
}

.hover-red:hover .v-list-item__title {
    color: red !important;
}
.v-simple-checkbox .mdi-checkbox-blank-outline {
    color: #ccc;
}
.involucroEcommerce {
    height: 450px;
    display: flex;
  margin-top: 20px;
  /* flex-grow: 1; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}
.involucroEcommerce .flex-table {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  height: 420px;
  width: 100%;
}
.involucroEcommerce .flex-table > div {
  width: 100%;
}
.involucroEcommerce .flex-table .v-data-table__wrapper {
	overflow-y: auto !important;
} 
</style>