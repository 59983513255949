<template>
  <v-form
    v-on:submit.prevent="filtrar"
    ref="formFiltro"
    class="filtros-acordos"
  >
    <v-row>
      <v-col class="flex-grow-1 pb-0">
        <v-toolbar-title class="page-title"
          >Gerencial Oportunidades</v-toolbar-title
        >
      </v-col>
      <v-col cols="3" class="px-2 pb-0">
        <v-select
          label="Colunas Agrupadas"
          :items="cols_agrupado"
          v-model="agrupar"
          :menu-props="menuProps"
          class="input-cinza"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="agrupar.length === 1 && index === 0">{{
              item.text
            }}</span>
            <span
              v-if="agrupar.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ agrupar.length }} selecionados</span
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-3"></v-divider>
    <v-row>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <MesInput
          label="Período Inicial"
          v-model="mesInicial"
          :rules="regra.mesInicial"
          :change="mesInicialChange"
          required
        ></MesInput>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <MesInput
          label="Período Final"
          v-model="mesFinal"
          :rules="regra.mesFinal"
          :change="mesFinalChange"
          required
        ></MesInput>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-autocomplete
          label="Ativação"
          class="input-cinza"
          outlined
          dense
          :items="lista.ativacoes"
          v-model="id_ativacao"
          :menu-props="menuProps"
          multiple
        ></v-autocomplete>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-autocomplete
          label="Categoria"
          class="input-cinza"
          outlined
          dense
          :items="lista.categorias"
          v-model="id_categoria"
          :menu-props="menuProps"
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <div
              v-if="id_categoria.length === 1 && index === 0"
              class="autocomplete-selecao"
            >
              {{ item.text }}
            </div>
            <span
              v-if="id_categoria.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ id_categoria.length }} selecionados</span
            >
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-expansion-panels flat v-model="mostrarFiltros" accordion>
      <v-expansion-panel>
        <v-expansion-panel-content eager class="p-0 m-0">
          <v-row>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Bandeira"
                v-model="id_bandeira"
                :items="lista.bandeira"
                :menu-props="menuProps"
                outlined
                dense
                class="input-cinza"
                multiple
              >
              </v-select>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Device"
                outlined
                dense
                class="input-cinza"
                v-model="device"
                :items="lista.device"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>

            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Tipo/Área"
                outlined
                dense
                class="input-cinza"
                v-model="id_area"
                :items="lista.areas"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Semana"
                outlined
                dense
                class="input-cinza"
                v-model="semana"
                :items="listaSemanas"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Fornecedor"
                v-model="id_fornecedor"
                :items="lista.fornecedor"
                multiple
                :menu-props="menuProps"
                outlined
                dense
                class="input-cinza"
              >
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="id_fornecedor.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="id_fornecedor.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ id_fornecedor.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
               <!-- <v-select
                label="Termo"
                outlined
                dense
                class="input-cinza"
                v-model="id_termo"
                :items="lista.termos"
                :menu-props="menuProps"
                multiple
              ></v-select> -->
              <v-autocomplete
                    label="Termo"
                    outlined
                    dense
                    multiple
                    class="input-cinza"
                    v-model="id_termo"
                    :items="lista.termos"
                    item-value="value"
                    item-text="text"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="lista.termos.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="lista.termos.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ id_termo.length }} selecionados</span
                        >
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Macro fornecedores"
                outlined
                dense
                class="input-cinza"
                v-model="id_macrofornecedor"
                :items="lista.macrofornecedor"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider class="mt-0 mb-3"></v-divider>
    <v-row>
      <v-col cols="3" class="px-2 ml-auto">
        <div class="new-button d-flex align-center" text>
          <span @click="expandirFiltros" style="cursor: pointer"
            >Personalizar Filtros</span
          >
          <v-btn
            style="background: #e6e6e6; width: 20px; height: 20px"
            fab
            icon
            class="ml-3"
            @click="expandirFiltros"
            x-small
          >
            <v-icon>{{
              mostrarFiltros == 0 ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="9" class="ml-auto" align="end">
        <v-btn class="ml-2 new-button" color="yellow" dark @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
        </v-btn>
        <v-btn class="ml-5 new-button" color="primary" type="submit">
          <v-icon left>mdi-filter</v-icon> Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="carregando">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
import useFiltros from "@/hooks/useFiltros";
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'gerencial_filtro',
  setup() {
    const { isLoading, isError, isFetching, lista, error, refetch } =
      useFiltros("/planejamentoEcommerce/listaCampos");

    return { isLoading, isError, isFetching, lista, error, refetch };
  },
  // inject: ["listaCampos"],
  data() {
    return {
      carregando: false,
      mostrarFiltros: null,
      listaSemanas: [
        { value: 1, text: "Semana 1" },
        { value: 2, text: "Semana 2" },
        { value: 3, text: "Semana 3" },
        { value: 4, text: "Semana 4" },
        { value: 5, text: "Semana 5" },
      ],
      cols_agrupado: [
        { text: "Bandeira", value: "bandeira" },
        { text: "Ativação", value: "ativacao" },
        { text: "Categoria", value: "categoria" },
        { text: "Fornecedor", value: "fornecedor" },
        { text: "Área", value: "area" },
        { text: "Biblioteca de termos", value: "termo" },
        { text: "Macro fornecedores", value: "macrofornecedor" },
        { text: "Device", value: "device" },
      ],
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      // filtro: {
      //   mesInicial: this.$moment().format("YYYY-MM"),
      //   mesFinal: this.$moment().format("YYYY-MM"),
      // },
      regra: {
        mesInicial: [
          (v) =>
            (this.tipoPeriodo < 1 && !!v) ||
            "O campo período inicial é obrigatório",
        ],
        mesFinal: [
          (v) =>
            (this.tipoPeriodo < 1 && !!v) ||
            "O campo período final é obrigatório",
          (v) =>
            (this.tipoPeriodo < 1 &&
              !!v &&
              v >= this.mesInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
      },
    };
  },
  async created() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // this.iniciaFiltro();
    // this.carregando = false;
  },
  computed: {
    ...mapFields('planejamento_ecommerce_gerencial',[
        'filtro.mesInicial',
        'filtro.mesFinal',
        'filtro.tipoPeriodo',
        'filtro.id_bandeira',
        'filtro.id_planejamento',
        'filtro.semana',
        'filtro.id_ativacao',
        'filtro.device',
        'filtro.id_area',
        'filtro.id_fornecedor',
        'filtro.id_categoria',
        'filtro.agrupar',
        'filtro.plano',
        'filtro.id_status_planejamento',
        'filtro.id_termo',
        'filtro.id_macrofornecedor'
    ]),
  },
  methods: {
    
    // iniciaFiltro() {
    //   this.filtro = {
    //     mesInicial: this.$moment().format("YYYY-MM"),
    //     mesFinal: this.$moment().format("YYYY-MM"),
    //     tipoPeriodo: -1,
    //     id_bandeira: [],
    //     id_planejamento: null,
    //     semana: [],
    //     id_ativacao: [],
    //     device: [],
    //     id_area: [],
    //     id_fornecedor: [],
    //     id_categoria: [],
    //     agrupar: [],
    //     plano: [],
    //     id_status_planejamento: [],
    //   };
    // },
    limpar() {
      // this.iniciaFiltro();

      this.$emit("limpar");
    },
    filtrar() {
      this.$emit("filtrar");
    },
    validate() {
      return this.$refs.formFiltro.validate();
    },
    expandirFiltros() {
      this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
    },
    mesInicialChange(valor) {
      if (this.mesFinal < valor) {
        this.mesFinal = valor;
      }
    },
    mesFinalChange(valor) {
      if (this.mesInicial > valor) {
        this.mesInicial = valor;
      }
    },
    resetValidation() {
      try {
        this.$refs.formFiltro.resetValidation();
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>
