import { getField, updateField } from 'vuex-map-fields'
import { http } from '../config/http'

export default {
    namespaced: true, 
    state: {
        downloadEvents: [],
        dados: [],
        total: 0,
        pagination: {},
        filtro: {
            mes: "",
            vbZerado: false,
            apenasCampanhas: false,
            cuboMovelDermoCampanha: false,
            bandeira: [],
            id_espaco: [],
            espaco_macro: [],
            id_fornecedor: [],
            codigo: [],
            id_cluster: [],
            id_filial: [],
            regiao: [],
            perfil: [],
            tamanho: [],
            grupo: [],
            subgrupo: [],
            categoria: [],
            subcategoria: [],
            cuboMovelDermo: false,
            vbZeradoQuantidade: null,
            cuboMovelDermoQuantidade: null,
            apenasCampanhasQuantidade: null,
            cuboMovelDermoCampanhaQuantidade: null,
            supply: false
        },
    },    
    getters: {
        getField
    },
    mutations: {
        updateField,
        resetFiltros(state) {
            state.filtro = {
                mes: "",
                vbZerado: false,
                apenasCampanhas: false,
                cuboMovelDermoCampanha: false,
                bandeira: [],
                id_espaco: [],
                espaco_macro: [],
                id_fornecedor: [],
                id_cluster: [],
                codigo: [],
                id_filial: [],
                regiao: [],
                perfil: [],
                tamanho: [],
                grupo: [],
                subgrupo: [],
                categoria: [],
                subcategoria: [],
                cuboMovelDermo: false,
                vbZeradoQuantidade: null,
                cuboMovelDermoQuantidade: null,
                apenasCampanhasQuantidade: null,
                cuboMovelDermoCampanhaQuantidade: null,
                supply: false
            };
        },
        update(state,payload) {
            for (var item in payload) {
                state[item] = payload[item]
            }
        },
        updateFiltro(state,payload) {
            for (var item in payload) {
                state.filtro[item] = payload[item]
            }
        },
        addDownloadEvents(state,payload) {
            state.downloadEvents.push({...payload,total:state.total})
        }
    },
    actions: {
        iniciaFiltro(context) {
            context.commit('resetFiltros')
        },
        async busqueDados(context) {
			var data = { ...context.state.filtro };

            console.log(data)

            // data.codigo = data.codigo.lenght > 0 ? data.codigo.split(',').map(item => parseInt(item) ) : []



			data.mes = [data.mes + "-01"];

			data.pagination = context.state.pagination;

			var response = await http().post("/gerencial/vb", {
				data: data,
			});

            let dados =
				response.data.lista?.name === "error"
					? [response.data.lista]
					: response.data.lista;
            context.commit('update',{dados:dados})
		},	
        async busqueTotal(context) {
            var data = { ...context.state.filtro };

            data.mes = [data.mes + "-01"];
            // data.codigo = data.codigo.lenght > 0 ? data.codigo.split(',').map(item => parseInt(item) ) : []

            var response = await http().post("/gerencial/vbTotal", {
                data: data,
            });

            let total = response.data.total
                ? response.data.total * 1
                : 0;

            if (total == 0 && context.state.pagination.page > 1) {
                context.commit('update',{pagination:1})
            }
            if (context.state.filtro.vbZerado) {
                console.log({vbZeradoQuantidade:total})
                context.commit('updateFiltro',{vbZeradoQuantidade:total})    
            }
            if (context.state.filtro.cuboMovelDermo) {
                console.log({cuboMovelDermoQuantidade:total})
                context.commit('updateFiltro',{cuboMovelDermoQuantidade:total})    
            }
            if (context.state.filtro.apenasCampanhas) {
                console.log({apenasCampanhasQuantidade:total})
                context.commit('updateFiltro',{apenasCampanhasQuantidade:total})    
            }
            if (context.state.filtro.cuboMovelDermoCampanha) {
                console.log({cuboMovelDermoCampanhaQuantidade:total})
                context.commit('updateFiltro',{cuboMovelDermoCampanhaQuantidade:total})    
            }
            context.commit('update',{total:total})
        },	
        async agendeExportar(context) {
            var data = { ...context.state.filtro };

            data.mes = [data.mes + "-01"];

            var response = await http().post("/gerencial/agendeExportarVb", {
                data: data,
            });
            console.log(response.data)
            context.commit('addDownloadEvents',response.data[0])
        }    
    },	
}        