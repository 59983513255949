<template>
    <v-dialog content-class="br-15" v-model="dialogo" width="900">
        <v-card>
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title> Importar Base de Produtos </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    light
                    @click="dialogo = false"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <div class="instrucoes mt-6">
                    <h4 color="primary">
                        Para importar uma base de produtos, siga as instruções
                        abaixo
                    </h4>
                    <ul>
                        <li>
                            A base utilizada para importação deve ser parecido
                            ao modelo extraída acima (Modelo de Importação).
                        </li>
                        <li>
                            A colunas <strong>codigo</strong> (Código do
                            Produto) e <strong>importar</strong> (Sim ou não)
                            são obrigatórias (<strong
                                >e os nomes das colunas devem ser identicos aos
                                da base exportada</strong
                            >), as demais colunas podem ser excluidas.
                        </li>
                        <li>
                            Só é indicado alterações na coluna
                            <strong>importar</strong>.
                            <strong
                                >Se outra coluna for alterada a linha pode ser
                                desconsiderada.</strong
                            >
                        </li>
                        <li>
                            Para adicionar um <strong>produto</strong> ao
                            <strong>acordo</strong>, adicione o texto
                            <strong>Sim</strong> na coluna
                            <strong>importar</strong> para o
                            <strong>produto</strong> desejado.
                        </li>
                        <li>
                            Para remover um <strong>produto</strong> do
                            <strong>acordo</strong>, deixe o valor
                            <strong>vazio</strong> (em branco) ou coloque o
                            texto <strong>Não</strong> para o
                            <strong>produto</strong> desejado, ou simplesmente
                            remova a linha com o <strong>produto</strong>.
                        </li>
                        <li>
                            Qualquer valor
                            <strong> que seja diferente de "Sim"</strong> na
                            coluna <strong>importar</strong> será considerado
                            como <strong>"Não"</strong>.
                        </li>
                        <li>
                            Não faz diferença se a coluna
                            <strong>importar</strong> está escrita em maiúsculo
                            ou minúsculo.
                        </li>
                    </ul>
                </div>
                <div v-if="!file">
                    <div class="drop" @drag="onChange">
                        <div
                            :class="[
                                'dropZone',
                                dragging ? 'dropZone-over' : '',
                            ]"
                        >
                            <div class="dropZone-info">
                                <span class=""
                                    >Arraste seu Arquivo CSV, ou então
                                    <span class="clickable">clique aqui</span>
                                    para selecionar.</span
                                >
                            </div>
                            <input
                                @dragenter="dragging = true"
                                @dragleave="dragging = false"
                                type="file"
                                @change="onChange"
                            />
                        </div>
                    </div>
                </div>
                <div v-else class="dropZone-uploaded">
                    <div class="dropZone-uploaded-info d-flex">
                        <span> {{ file.name }} </span>
                        <span class="removeFile ml-3" @click="removeFile">
                            <v-icon size="18" color="#ccc"
                                >mdi-close-circle</v-icon
                            >
                        </span>
                    </div>
                </div>
                <v-alert
                    type="error"
                    transition="scale-transition"
                    dismissible
                    v-model="alerta"
                >
                    {{ alertaMesagem }}
                </v-alert>
                <v-expand-transition>
                    <div v-show="erroModelo">
                        <v-row no-gutters class="mb-0">
                            <v-col cols="6">
                                <download-csv
                                    :data="listaErro"
                                    :labels="listaErroLabels"
                                    name="erros.csv"
                                    delimiter=";"
                                >
                                    <v-btn color="primary">
                                        <v-icon left
                                            >mdi-microsoft-excel</v-icon
                                        >
                                        Exportar Erros
                                    </v-btn>
                                </download-csv>
                            </v-col>
                            <v-col cols="6" align="end">
                                <v-btn
                                    color="primary"
                                    @click="ignorarErrosProdutos()"
                                >
                                    <v-icon left>mdi-alert-remove</v-icon>
                                    Ignorar Erros
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro"
                            class="elevation-0 data-table-2"
                        ></v-data-table>
                    </div>
                </v-expand-transition>
                <v-row no-gutters>
                    <!-- <v-col cols="8" class="px-2">
                        <v-form v-model="isCsv">
                            <v-file-input
                                label="Arquivo CSV"
                                outlined
                                dense
                                v-model="csvModelo"
                                :rules="regra.csv"
                                accept=".csv"
                            ></v-file-input>
                        </v-form>
                    </v-col> -->
                    <!-- <v-col cols="4" class="px-2">
                        <v-btn
                            color="primary"
                            :disabled="!isCsv"
                            @click="importarProdutos()"
                        >
                            <v-icon left>mdi-upload</v-icon>
                            Importar Produtos
                        </v-btn>
                    </v-col> -->
                </v-row>

                <v-expand-transition>
                    <div v-show="erroModelo">
                        <v-row no-gutters class="mb-0">
                            <v-col cols="6">
                                <download-csv
                                    :data="listaErro"
                                    :labels="listaErroLabels"
                                    name="erros.csv"
                                    delimiter=";"
                                >
                                    <v-btn color="primary">
                                        <v-icon left
                                            >mdi-microsoft-excel</v-icon
                                        >
                                        Exportar Erros
                                    </v-btn>
                                </download-csv>
                            </v-col>
                            <v-col cols="6" align="end">
                                <v-btn
                                    color="primary"
                                    @click="ignorarErrosProdutos()"
                                >
                                    <v-icon left>mdi-alert-remove</v-icon>
                                    Ignorar Erros
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro"
                            class="elevation-0 data-table-2"
                        ></v-data-table>
                    </div>
                </v-expand-transition>
            </v-card-text>
            <v-card-actions class="pb-4 pt-0">
                <v-btn
                    color="yellow"
                    class="new-button ml-2"
                    @click="exportarModelo()"
                    dark
                >
                    <v-icon left>mdi-microsoft-excel</v-icon>
                    Baixar modelo de importação
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    class="new-button mr-2"
                    @click="importarProdutos()"
                >
                    <v-icon left>mdi-upload</v-icon>
                    Importar Produtos
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'importarProdutos',
    props: ['assinatura'],
    inject: ['listaCampos'],
    data() {
        return {
            metodo_criacao: 'PD',
            carregando: false,
            dadosTemp: [],
            produtoServidor: [],
            produtos_por_cluster: false,
            clusters: [
                'POPULAR',
                'SUPER POPULAR',
                'HIBRIDA',
                'NOBRE',
                'SUPER NOBRE',
                'AMPLA',
            ],
            dialogo: false,
            csvModelo: null,
            file: '',
            isCsv: false,
            alerta: false,
            alertaMesagem: null,
            dragging: false,
            listaHeaderErro: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left' },
            ],
            listaErro: [],
            erroModelo: false,
            listaErroLabels: { msg: 'Descrição do Erro' },
            paginationErro: {},
            regra: {
                csv: [
                    (v) => !!v || 'Selecione um arquivo.',
                    (v) =>
                        (v && ['.csv', '.CSV'].includes(v.name.substr(-4))) ||
                        'O arquivo deve ser do tipo CSV.',
                ],
            },
        }
    },
    async provide() {
        return {
            listaCampos: await this.listaCampos,
        }
    },
    methods: {
        exportarModelo() {
            this.$emit('exportarModelo')
        },
        onChange(e) {
            var files = e.target.files || e.dataTransfer.files

            if (!files.length) {
                this.dragging = false
                return
            }

            this.createFile(files[0])
        },
        createFile(file) {
            this.file = file
            if (this.extension !== 'csv') {
                console.log(file)
                this.alerta = true
                this.alertaMesagem = 'O arquivo deve ser do tipo CSV.'
                this.dragging = false
                this.file = ''
                return
            }

            console.log(this.file)
            this.dragging = false
        },
        removeFile() {
            this.file = ''
        },
        abrir(produtoServidor, metodo_criacao, produtos_por_cluster) {
            this.metodo_criacao = metodo_criacao
            this.produtos_por_cluster = produtos_por_cluster
            this.file = ''
            this.produtoServidor = produtoServidor.map((a) => ({ ...a }))

            this.csvModelo = null
            this.alerta = false
            this.alertaMesagem = null
            this.erroModelo = false
            this.listaErro = []
            this.dialogo = true
            this.dadosTemp = []
        },
        async importarProdutos() {
            this.alerta = false
            this.alertaMesagem = null
            this.erroModelo = false
            this.listaErro = []
            this.dadosTemp = []

            const parseCsv = async (csv) => {
                return new Promise((resolve) => {
                    this.$papa.parse(csv, {
                        delimiter: ';',
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: function (results) {
                            resolve(results)
                        },
                    })
                })
            }
            /*if (this.file.name.indexOf(this.assinatura) == -1) {
                this.alerta = true;
                this.alertaMesagem =
                    "Erro ao ler o arquivo CSV. Assinatura nao confere.";
                return;    
            }
            */
            var csv = await parseCsv(this.file)

            if (csv.errors.length) {
                this.alerta = true
                this.alertaMesagem =
                    'Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.'
            } else {
                this.dadosTemp = []// this.produtoServidor.map((p) => ({ ...p }))

                let produtoDuplicado = false
                csv.data.forEach((element) => {
                    const produto = this.produtoServidor.find(
                        (p) => p.codigo == element.codigo
                    )
                    const selecionado = element.selecionado.toLowerCase()
                    if (
                        produto &&
                        (selecionado == 'sim')
                    ) {
                        produto.selecionado = true

                        // check if product exists in dadosTemp
                        const produtoTemp = this.dadosTemp.find(
                            (p) => p.codigo == element.codigo
                        )

                        if (!produtoTemp) {
                            this.dadosTemp.push(produto)
                        } else {
                            produtoDuplicado = true
                        }
                    }
                })

                if (produtoDuplicado) {
                    this.$dialog.notify.warning(
                        'na base importada havia produtos duplicados, as duplicidades foram desconsideradas e os produtos salvos no acordo',
                        {
                            position: 'top-right',
                        }
                    )
                }

                if (this.listaErro.length > 0) {
                    this.paginationErro.page = 1
                    this.erroModelo = true
                } else {
                    this.$emit('importar')

                    this.dialogo = false
                }
            }
        },
        ignorarErrosProdutos() {
            this.$emit('importar')

            this.dialogo = false
        },
    },
    computed: {
        extension() {
            return this.file
                ? this.file.name.split('.').pop().toLowerCase()
                : ''
        },
    },
}
</script>
<style >
.instrucoes {
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 6px;
    color: #808080;
}
.instrucoes h4 {
    color: var(--v-primary-base);
    margin-bottom: 6px;
}

.dropZone {
    width: 100%;
    height: 90px;
    position: relative;
    color: #808080;
}

.dropZone:hover {
    /* border: 2px solid #2e94c4; */
}

.dropZone:hover .dropZone-title {
    color: #1975a0;
}

.dropZone-info {
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50px;
    transform: translate(0, -50%);
    padding: 10px 15px;
    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 6px;
}

.dropZone-title {
    color: #808080;
    margin-top: 20px;
}

.dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: blue;
}

.dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropZone-over .dropZone-info {
    border: 2px dashed #ccc;
}

.dropZone-uploaded {
    width: 100%;
    height: 50px;
    margin: 20px 0;
    padding: 10px 15px;

    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 6px;
    position: relative;
}

.dropZone-uploaded-info {
    display: flex;
    align-items: center;
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.removeFile:hover {
    color: red;
    cursor: pointer;
}
.clickable {
    cursor: pointer;
    text-decoration: underline;
}
</style>