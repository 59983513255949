import { render, staticRenderFns } from "./Resumo.vue?vue&type=template&id=6a6a154a"
import script from "./Resumo.vue?vue&type=script&lang=js"
export * from "./Resumo.vue?vue&type=script&lang=js"
import style0 from "./Resumo.vue?vue&type=style&index=0&id=6a6a154a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VDivider,VForm,VOverlay})
